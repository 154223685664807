<template>
	<div class="customer">
		<div class="breadcrumb">
			<el-breadcrumb separator="/">
				<!-- <el-breadcrumb-item :to="{ path: '/user/my-collect' }"
          >我的收藏</el-breadcrumb-item
        > -->
				<el-breadcrumb-item :to="{ path: pathUrl }">{{ pathName }}</el-breadcrumb-item>
				<el-breadcrumb-item>客户信息详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="top-main copy">
			<!-- <div class="code-img-box">
        <img
          class="code-img"
          @dragstart.prevent
          src="../../../assets/img/user/code.png"
          alt=""
        />
        <img
          class="code-float animated fadeIn"
          @dragstart.prevent
          src="../../../assets/img/code.png"
          alt=""
        />
      </div> -->
			<img class="left-img" v-if="mdsClientInfo.logoUrl" @dragstart.prevent :src="mdsClientInfo.logoUrl" alt="" />
			<div class="img-left-logo" v-else style="background-color: #6daae2">
				<div class="logo-img">{{ mdsClientInfo.logoImg }}</div>
			</div>
			<div class="main-right">
				<div class="top-name">
					<div class="name">{{ mdsClientInfo.clientName }}</div>
					<div class="name-right">
						<div class="right-item" v-show="mdsClientInfo.isJoin == 0" @click="joinMyClient(1)">
							<img class="add-icon" @dragstart.prevent src="../../../assets/img/user/add.png" alt="" />
							<div>加入客户列表</div>
						</div>
						<div class="right-item" v-show="mdsClientInfo.isJoin == 1" @click="joinMyClient(0)">
							<img class="add-icon" @dragstart.prevent src="../../../assets/img/user/yichu.png" alt="" />
							<div style="color: #1181fa">移除客户列表</div>
						</div>
						<div class="right-item" v-show="mdsClientInfo.isJsubscribe == 0" @click="jsubscribeClient(1)">
							<img class="take-icon" @dragstart.prevent src="../../../assets/img/user/dingyue.png" alt="" />
							<div>加入关注</div>
						</div>
						<div class="right-item" v-show="mdsClientInfo.isJsubscribe == 1" @click="jsubscribeClient(0)">
							<img class="take-icon" @dragstart.prevent src="../../../assets/img/user/quxiaodingyue.png"
								alt="" />
							<div class="take">取消关注</div>
						</div>
						<div class="right-item" @click="toNotepad">
							<img class="notepad-icon" @dragstart.prevent src="../../../assets/img/user/notepad.png" alt=""
								v-show="mdsClientInfo.noteCount == 0" />
							<img src="@/assets/img/user/notepad-blue.png" @dragstart.prevent class="notepad-icon" alt=""
								v-show="mdsClientInfo.noteCount != 0" />
							<div class="notepad" :style="{
								color: mdsClientInfo.noteCount == 0 ? '' : '#1181FA',
							}">
								<span>记事本</span>
								<span class="line">|</span>
								<span>{{ mdsClientInfo.noteCount }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="main-content">
					<div class="item-box">
						<div class="item-left">企业法人：{{ mdsClientInfo.legalPerson || "-" }}</div>
						<div class="item-right">注册资本：{{ mdsClientInfo.registeredCapital || "-" }}</div>
					</div>
					<div class="item-box">
						<div class="item-left">成立日期：{{ mdsClientInfo.establishDate || "-" }}</div>
						<div class="item-right">联系电话：{{ mdsClientInfo.phone || "-" }}</div>
					</div>
					<div class="item-box">
						<div class="item-left">
							<div>微信公众号：{{ mdsClientInfo.wechat || "-" }}</div>
						</div>
						<div class="item-right">
							企业官网：
							<el-tooltip effect="light" :content="mdsClientInfo.websiteLink" placement="bottom-start"
								v-if="mdsClientInfo.websiteLink">
								<span class="link text-ellipsis" @click.stop="toLink(mdsClientInfo.websiteLink)">{{
									mdsClientInfo.websiteLink }}</span>
							</el-tooltip>
							<span v-else class="link-none">-</span>
						</div>
					</div>
					<div class="item-box">
						<div class="item-left" style="width: 750px">企业地址：{{ mdsClientInfo.address || "-" }}</div>
					</div>
					<!-- <div class="item-box">
            <div class="item-left">添加人：{{ mdsClientInfo.createByName || "-" }}</div>
            <div class="item-right">添加时间：{{ mdsClientInfo.createDate || "-" }}</div>
          </div>
          <div class="item-box">
            <div class="item-left">
              最后更新人：{{ mdsClientInfo.updateByName || "-" }}
            </div>
            <div class="item-right">
              最后更新时间：{{ mdsClientInfo.updateDate || "-" }}
            </div>
          </div> -->
					<!-- <div class="item-box">
            <div class="item-left">
              <div>添加人：{{ mdsClientInfo.createByName || "-" }}</div>
              <div style="margin-left: 20px">
                添加时间：{{ mdsClientInfo.createDate || "-" }}
              </div>
            </div>
            <div class="item-right">
              <div>最后更新人：{{ mdsClientInfo.updateByName || "-" }}</div>
              <div style="margin-left: 20px">
                最后更新时间：{{ mdsClientInfo.updateDate || "-" }}
              </div>
            </div>
          </div> -->
				</div>
			</div>
		</div>
		<!-- tab页面详情 -->
		<div class="main-msg-box">
			<div class="nav-box" :class="activeName == 'navFour' || activeName == 'trackRecord' ? 'nav-box-four' : ''">
				<div :class="activeName == 'navFive' ? 'nav-box-five' : ''">
					<el-tabs v-model="activeName" :before-leave="beforeleaveNav">
						<el-tab-pane label="基本信息" name="navOne" v-if="type == 1"></el-tab-pane>
						<el-tab-pane label="历史项目数据" name="navFour"></el-tab-pane>
						<el-tab-pane label="客户动态" name="navTwo"></el-tab-pane>
						<el-tab-pane label="招标动态" name="navThree"></el-tab-pane>
						<el-tab-pane label="中标动态" name="navSix"></el-tab-pane>
						<el-tab-pane label="人员信息" name="personInfo"></el-tab-pane>
						<el-tab-pane label="项目跟踪记录" name="trackRecord"></el-tab-pane>
						<el-tab-pane label="客户资料库" name="navFive"></el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<div class="nav-main">
				<div v-show="activeName == 'navOne' && type == 1" class="copy">
					<div class="empty-box"
						v-show="!detail.introduction && !clientBasic && shareholderList.length == 0 && principalList.length == 0 && investmentList.length == 0 && controlsList.length == 0 && branchesList.length == 0 && !mdsClientActualOwner"
						style="margin: 40px 0 30px 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="main-title" v-show="detail.introduction">企业简介</div>
					<div class="intro" v-show="detail.introduction">
						{{ detail.introduction || "-" }}
					</div>
					<div class="main-title" v-show="clientBasic">工商信息</div>
					<div class="business-info" v-show="clientBasic">
						<div class="table-row">
							<div class="table-header">企业法人</div>
							<div class="table-mian">{{ clientBasic.legalPerson || "-" }}</div>
							<div class="table-header">经营状态</div>
							<div class="table-mian">
								{{ clientBasic.businessStatus || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">成立时间</div>
							<div class="table-mian">
								{{ clientBasic.establishDate || "-" }}
							</div>
							<div class="table-header">核准日期</div>
							<div class="table-mian">{{ clientBasic.examineDate || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">注册资本</div>
							<div class="table-mian">
								{{ clientBasic.registeredCapital || "-" }}
							</div>
							<div class="table-header">实缴资本</div>
							<div class="table-mian">{{ clientBasic.paidCapital || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">统一社会信用代码</div>
							<div class="table-mian">{{ clientBasic.creditCode || "-" }}</div>
							<div class="table-header">组织机构代码</div>
							<div class="table-mian">
								{{ clientBasic.organizationCode || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">工商注册号</div>
							<div class="table-mian">
								{{ clientBasic.registrationNumber || "-" }}
							</div>
							<div class="table-header">经营期限</div>
							<div class="table-mian">
								{{ clientBasic.businessPeriod || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">所属行业</div>
							<div class="table-mian">
								{{ clientBasic.subordinateIndustry || "-" }}
							</div>
							<div class="table-header">企业类型</div>
							<div class="table-mian">{{ clientBasic.companyType || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">登记机关</div>
							<div class="table-mian">
								{{ clientBasic.registrationAuthority || "-" }}
							</div>
							<div class="table-header">所属地区</div>
							<div class="table-mian">{{ clientBasic.area || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">企业注册地址</div>
							<div class="registered-ddress">
								{{ clientBasic.address || "-" }}
							</div>
						</div>
						<div class="table-row-end">
							<div class="table-header-end">经营范围</div>
							<div class="table-mian-end">
								{{ clientBasic.businessScope || "-" }}
							</div>
						</div>
					</div>
					<div class="main-title" v-show="shareholderList.length != 0">股东信息</div>
					<div class="shareholder-table" v-show="shareholderList.length != 0">
						<el-table header-cell-class-name="table_header" :data="shareholderList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="name" label="股东名称" align="center"></el-table-column>
							<el-table-column prop="totalRealCapi" label="总实缴" align="center"></el-table-column>
							<el-table-column prop="identifyNo" label="企业证照号" align="center"></el-table-column>
							<el-table-column prop="totalShouldCapi" label="总认缴" align="center"></el-table-column>
							<el-table-column prop="stockPercent" label="持股比例" align="center"></el-table-column>
							<el-table-column prop="identifyType" label="类型" align="center"></el-table-column>
							<el-table-column prop="stockType" label="股东类型" align="center"></el-table-column>
							<el-table-column prop="capiDate" label="出资时间" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="shareholderTotal" @change="shareholderPaginChange"
							v-show="shareholderTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="principalList.length != 0">主要人员</div>
					<div class="shareholder-table" v-show="principalList.length != 0">
						<el-table header-cell-class-name="table_header" :data="principalList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="principalName" label="姓名" align="center"></el-table-column>
							<el-table-column prop="principalPosition" label="职务" align="center"></el-table-column>
							<el-table-column prop="isHistory" label="是否为历史人员" align="center">
								<template slot-scope="scope">
									<span>{{ scope.row.isHistory == "10011001" ? "是" : "否" }}</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="principalTotal" @change="principalPaginChange"
							v-show="principalTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="investmentList.length != 0">对外投资</div>
					<div class="shareholder-table" v-show="investmentList.length != 0">
						<el-table header-cell-class-name="table_header" :data="investmentList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="enterprise" label="股东名称" align="center"></el-table-column>
							<el-table-column prop="shortName" label="简称" align="center"></el-table-column>
							<el-table-column prop="amount" label="投资金额(万元)" align="center"></el-table-column>
							<el-table-column prop="percent" label="持股比例" align="center"></el-table-column>
							<el-table-column prop="newStatus" label="经营状态" align="center">
								<template slot-scope="scope">
									<span>{{ getNewStatus(scope.row.newStatus) }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="shType" label="股东类型" align="center"></el-table-column>
							<el-table-column prop="investCreditNo" label="股东统一社会信用代码" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="investmentTotal" @change="investmentPaginChange"
							v-show="investmentTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="controlsList.length != 0">实际控制企业</div>
					<div class="shareholder-table" v-show="controlsList.length != 0">
						<el-table header-cell-class-name="table_header" :data="controlsList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="pname" label="投资方" align="center"></el-table-column>
							<el-table-column prop="ename" label="投资企业名称" align="center"></el-table-column>
							<el-table-column prop="percent" label="控股比例" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="controlsTotal" @change="controlsPaginChange"
							v-show="controlsTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="branchesList.length != 0">分支机构</div>
					<div class="shareholder-table" v-show="branchesList.length != 0">
						<el-table header-cell-class-name="table_header" :data="branchesList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="name" label="分支机构名称" align="center"></el-table-column>
							<el-table-column prop="registCapi" label="注册资本" align="center"></el-table-column>
							<el-table-column prop="startDate" label="成立日期" align="center"></el-table-column>
							<el-table-column prop="belongOrg" label="登记机关" align="center"></el-table-column>
							<el-table-column prop="operName" label="企业法定代表人" align="center"></el-table-column>
							<el-table-column prop="status" label="企业状态" align="center"></el-table-column>
							<el-table-column prop="creditNo" label="统一社会信用代码" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="branchesTotal" @change="branchesPaginChange"
							v-show="branchesTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="mdsClientActualOwner != ''">疑似实控人</div>
					<div class="person-box" v-show="mdsClientActualOwner != ''">
						<RelationGraph ref="seeksRelationGraph" :options="graphOptions" />
					</div>
				</div>
				<div v-show="activeName == 'personInfo'">
					<person-info ref="personInfo"></person-info>
				</div>
				<div v-show="activeName == 'navTwo'">
					<div class="title-box">
						<div class="main-title">客户动态</div>
						<div class="total-right">
							<span>共找到</span>
							<span class="total">{{ dynamicTotal }}</span>
							<span>条结果</span>
						</div>
					</div>
					<div class="time-picker">
						<div class="custom-box">
							<div class="custom">时间</div>
							<div>
								<el-date-picker v-model="dynamicStart" type="month" :picker-options="dynamicStartTime"
									placeholder="开始时间" value-format="yyyy-MM"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="dynamicEnd" type="month" :picker-options="dynamicEndTime"
									placeholder="结束时间" value-format="yyyy-MM"></el-date-picker>
							</div>
						</div>
						<el-button type="primary" @click="dynamicQuery">查询</el-button>
					</div>
					<div class="empty-box" v-show="dynamicTotal == 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="list-item" v-for="item in pageDynamic" :key="item.dynamicId"
						@click="toDynamicDetail(item.dynamicId)">
						<div class="name-left">
							<div class="tag-icon"></div>
							<el-tooltip effect="light" :content="item.title" placement="bottom-start">
								<div class="name-box text-ellipsis">
									{{ item.title }}
								</div>
							</el-tooltip>
						</div>
						<div class="date-right">
							<div class="serial-code">{{ item.websiteLocation }}</div>
							<div class="date">{{ item.publishedDate }}</div>
						</div>
					</div>
					<div class="pagination-box">
						<pagination ref="pagination" :total="dynamicTotal" @change="dynamicPaginChange"
							v-show="dynamicTotal != 0"></pagination>
					</div>
				</div>
				<div v-show="activeName == 'navThree'">
					<div class="title-box">
						<div class="main-title">招标动态</div>
						<div class="total-right">
							<span>共找到</span>
							<span class="total">{{ biddingTotal }}</span>
							<span>条结果</span>
						</div>
					</div>
					<div class="time-picker">
						<div class="custom-box">
							<div class="custom">时间</div>
							<div>
								<el-date-picker v-model="bidsStart" type="month" :picker-options="bidsStartTime"
									placeholder="开始时间" value-format="yyyy-MM"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="bidsEnd" type="month" :picker-options="bidsEndTime"
									placeholder="结束时间" value-format="yyyy-MM"></el-date-picker>
							</div>
						</div>
						<div class="search-right">
							<el-input placeholder="请输入招标公告标题" prefix-icon="el-icon-search" v-model="bidInput"></el-input>
							<el-button type="primary" @click="bidsQuery">查询</el-button>
						</div>
					</div>
					<div class="empty-box" v-show="biddingTotal == 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="list-bidding" v-for="(item, index) in pageBidding" :key="index" @click="toBidsDetail(item)">
						<div class="name-top">
							<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
								<div class="text-ellipsis">
									{{ item.projectName }}
								</div>
							</el-tooltip>
						</div>
						<div class="bottom-box">
							<div class="tag-bottom">
								<div class="tag-item">招标单位：{{ item.tenderee || "-" }}</div>
								<div class="tag-item">地址：{{ item.city }}</div>
							</div>
							<div class="date">发布时间：{{ item.publishedDate }}</div>
						</div>
					</div>
					<div class="pagination-box">
						<pagination ref="pagination" :total="biddingTotal" @change="biddingPaginChange"
							v-show="biddingTotal != 0"></pagination>
					</div>
				</div>
				<div v-show="activeName == 'navSix'">
					<div class="title-box">
						<div class="main-title">中标动态</div>
						<div class="total-right">
							<span>共找到</span>
							<span class="total">{{ biddingTotal }}</span>
							<span>条结果</span>
						</div>
					</div>
					<div class="time-picker">
						<div class="custom-box">
							<div class="custom">时间</div>
							<div>
								<el-date-picker v-model="bidsStart" type="month" :picker-options="bidsStartTime"
									placeholder="开始时间" value-format="yyyy-MM"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="bidsEnd" type="month" :picker-options="bidsEndTime"
									placeholder="结束时间" value-format="yyyy-MM"></el-date-picker>
							</div>
						</div>
						<div class="search-right">
							<el-input placeholder="请输入招标公告标题" prefix-icon="el-icon-search" v-model="bidInput"
								maxlength="200" clearable></el-input>
							<el-button type="primary" @click="bidsQuery">查询</el-button>
						</div>
					</div>
					<div class="empty-box" v-show="biddingTotal == 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="list-bidding" v-for="(item, index) in pageBiddingWinner" :key="index"
						@click="toSuccessfulBidsDetail(item)">
						<div class="name-top">
							<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
								<div class="text-ellipsis">
									{{ item.projectName }}
								</div>
							</el-tooltip>
						</div>
						<div class="bottom-box">
							<div class="tag-bottom">
								<div class="tag-item">中标单位：{{ item.bidWinner || "-" }}</div>
								<div class="tag-item">地址：{{ item.city }}</div>
							</div>
							<div class="date">发布时间：{{ item.publishedDate }}</div>
						</div>
					</div>
					<div class="pagination-box">
						<pagination ref="pagination" :total="biddingTotal" @change="biddingPaginChange"
							v-show="biddingTotal != 0"></pagination>
					</div>
				</div>
				<div v-show="activeName == 'navFour'">
					<div class="history-title">
						<div style="display: flex; align-items: center">
							<div class="main-title">历史项目数据</div>
							<!-- <div v-show="isTime" style="height: 12px; margin-left: 20px">时间：2021年1月-2022年12月</div> -->
						</div>
						<div class="title-right">
							<div class="table-show" v-show="showType == 1" @click="clickShowType(2)">
								<div class="icon-img"></div>
								<span>表格展示</span>
							</div>
							<div class="atlas-show" v-show="showType == 2" @click="clickShowType(1)">
								<span class="iconfont icon-tupu-"></span>
								<span>图谱展示</span>
							</div>
							<div v-if="downloadButtonShow" class="download-box" @click="toDownload">
								<span class="iconfont icon-download"></span>
								<span>下载</span>
							</div>
						</div>
					</div>
					<div class="nav-list">
						<div class="nav-item" :class="conPO.navIndex == 1 ? 'nav-item-select' : ''" @click="clickNav(1)">
							按招标人视角
						</div>
						<div class="nav-item" :class="conPO.navIndex == 2 ? 'nav-item-select' : ''" @click="clickNav(2)">
							按中标人视角
						</div>
						<div class="dataDescription" @click="openDataDescription">数据说明</div>
					</div>
					<div class="form-top">
						<div class="form-lable" style="width: 50px">时间</div>
						<div class="date-picker">
							<el-date-picker v-model="conPO.dateStart" type="month" :picker-options="startTime"
								placeholder="选择开始日期" value-format="yyyy-MM"></el-date-picker>
						</div>
						<div class="line"></div>
						<div class="date-picker">
							<el-date-picker v-model="conPO.dateEnd" type="month" :picker-options="endTime"
								placeholder="选择结束日期" value-format="yyyy-MM"></el-date-picker>
						</div>
						<div class="form-lable lable">项目金额</div>
						<el-form ref="rulesPrice" :model="conPO" :rules="rules" style="display: flex; align-items: center">
							<div>
								<el-form-item prop="winnerPriceStart" style="margin-bottom: 0">
									<el-input v-model="conPO.winnerPriceStart" oninput="value=value.replace(/[^0-9.]/g,'')"
										@blur="conPO.winnerPriceStart = $event.target.value" placeholder="最低金额（万）"
										@change="blurPrice('start')" clearable></el-input>
								</el-form-item>
							</div>
							<div class="line" style="margin-top: 1px"></div>
							<div>
								<el-form-item prop="winnerPriceEnd" style="margin-bottom: 0">
									<el-input v-model="conPO.winnerPriceEnd" oninput="value=value.replace(/[^0-9.]/g,'')"
										@blur="conPO.winnerPriceEnd = $event.target.value" placeholder="最高金额（万）"
										@change="blurPrice('end')" clearable></el-input>
								</el-form-item>
							</div>
						</el-form>

						<div class="note">
							<span class="iconfont icon-zhushibiaozhui"></span>
							<div class="note-title animated fadeIn">
								<span>某金额以上可只填最低金额；某金额以下可只填最高金额</span>
							</div>
						</div>
						<!-- <div class="search-button" v-if="conPO.navIndex == 1">
							<el-button type="primary" @click="handleQuery">搜索</el-button>
						</div> -->
					</div>
					<div class="form-bottom">
						<el-checkbox v-model="isBidConsortium">{{ conPO.navIndex == 1 ? "含联合体成员身份招标额" : "含联合体成员身份中标额"
						}}</el-checkbox>
						<div class="search-button" style="display: inline-block;margin-left: 205px;">
							<el-button type="primary" @click="handleQuery">搜索</el-button>
						</div>
					</div>

					<by-tenderee v-show="conPO.navIndex == 1" ref="byTenderee" @isSensitive="getIsSensitive"></by-tenderee>
					<by-bidwinner v-show="conPO.navIndex == 2" ref="byBidwinner"
						@isSensitive="getIsSensitive"></by-bidwinner>
				</div>
				<div v-show="activeName == 'trackRecord'">
					<track-record ref="trackRecord"></track-record>
				</div>
				<div v-show="activeName == 'navFive'">
					<div class="data-title">
						<span>客户资料库</span>
					</div>
					<div class="customer-title-box" v-if="addType">
						<!-- <div class="title-right" style="width: 110px" @click="handleUpload">
							<img src="@/assets/img/user/upload.png" width="100%" height="100%" @dragstart.prevent
								class="img-setting" />
							<span class="customer">上传资料</span>
						</div> -->
						<div class="title-right" @click="handleUpload">
							<img src="@/assets/img/user/newfile.png" width="100%" height="100%" @dragstart.prevent
								class="img-setting" />
							<span class="customer">新建记录文档</span>
						</div>
					</div>
					<div class="datum-box">
						<div class="datum-left">
							<span class="datum-title">资料分类</span>
							<span class="add" @click="handAddType">
								<img src="@/assets/img/user/jia.png" width="14px" height="14px" @dragstart.prevent
									class="img-setting" />
								<span class="add-style">新增分类</span>
							</span>
							<div class="datum-search">
								<el-input placeholder="请输入分类名称" prefix-icon="el-icon-search" clearable
									v-model.trim="typeName" @change="typeSearch" @keyup.enter.native="typeSearch"
									maxlength="10"></el-input>
							</div>
							<div class="file-list">
								<div class="empty-box" v-show="fileList.length == 0" style="margin-top: 40px">
									<empty :name="'暂无数据'"></empty>
								</div>
								<div class="file-item" :class="clickIndex == i ? 'file-item-select' : ''"
									v-for="(item, i) in fileList" :key="item.folderId" @click="selectFile(i, item)"
									@mouseover="hoverFile(i)" @mouseout="fileIndex = -1">
									<img class="file-icon" @dragstart.prevent v-show="item.isShare == 0 && !item.delflag"
										src="@/assets/img/user/wenjian.png" alt="" />
									<img class="file-icon" @dragstart.prevent v-show="item.isShare == 1 && !item.delflag"
										src="@/assets/img/user/share.png" alt="" />
									<img class="file-icon" @dragstart.prevent v-show="item.delflag"
										src="@/assets/img/user/siren.jpg" alt="" />
									<div class="file-name">{{ item.folderName }}</div>
									<img class="file-icon" @dragstart.prevent src="@/assets/img/user/xiugai.png" alt=""
										v-show="(fileIndex == i || clickIndex == i) && item.consumerId && item.delflag"
										@click.stop="handleEditFile(item)" />
									<img class="file-icon" @dragstart.prevent src="@/assets/img/user/del.png" alt=""
										style="margin-left: 5px"
										v-show="(fileIndex == i || clickIndex == i) && item.consumerId && item.delflag"
										@click.stop="handleDelFile(item.folderId)" />
								</div>
							</div>
							<div class="process">
								<span class="title">存储空间</span>
								<el-progress v-if="usedSize&&spaceSize" :percentage="usedSize/spaceSize" :stroke-width="10" :format="format"
									color="#1181fa"></el-progress>
							</div>
						</div>
						<div class="datum-right">
							<div class="empty-box" v-show="fileTotal == 0" style="margin-top: 150px">
								<empty :name="'暂无数据'"></empty>
							</div>
							<div class="datum-table" v-show="fileTotal != 0">
								<el-table :data="fileTable" header-cell-class-name="table_header" style="width: 100%"
									height="520">
									<el-table-column prop="fileName" label="文件名称" align="center" show-overflow-tooltip>
										<template slot-scope="scope">
											<span class="click-style" @click="handleViewFile(scope.row)">{{
												scope.row.fileName }}</span>
										</template>
									</el-table-column>
									<!-- <el-table-column prop="fileSizes" label="文件大小" align="center" show-overflow-tooltip>
										<template slot-scope="scope">
											<span>{{ scope.row.fileSizes }}</span>
										</template>
									</el-table-column> -->
									<el-table-column prop="createDate" label="上传时间" align="center"
										show-overflow-tooltip></el-table-column>
									<el-table-column prop="" label="操作" align="center" width="80">
										<template slot-scope="scope">
											<span class="click-style" @click="handleDelFileDtl(scope.row)"
												v-show="scope.row.delflag">删除</span>
											<span class="click-style" v-show="!scope.row.delflag">-</span>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<div class="pagination-datum">
								<pagination ref="pagination" :total="fileTotal" @change="paginChange"
									v-show="fileTotal != 0"></pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<notepad-modal ref="notepadModal"></notepad-modal>
		<!-- 上传资料   dialog -->
		<div class="wai-fujian">
			<Modal :visible.sync="uploadVisible" @reset="resetFields" class="dialog-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
					{{ uploadTitle }}
				</div>
				<el-form :model="uploadData" :rules="uploadRules" ref="uploadForm" label-width="100px"
					class="demo-ruleForm">
					<el-row>
						<el-col :span="12">
							<el-form-item label="资料名称" prop="fileName" class="title-item">
								<el-input v-model.trim="uploadData.fileName" maxlength="30" placeholder="请输入资料名称"
									@input="inputFileName"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="所属分类" prop="folderId" class="title-item">
								<el-select v-model="uploadData.folderId" placeholder="请选择所属分类">
									<el-option v-for="item in typeList" :key="item.folderId" :label="item.folderName"
										:value="item.folderId">{{ item.folderName }}</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="文本内容" prop="detail" class="upload-item">
								<!-- 富文本 -->
								<editor v-model="uploadData.detail" :height="240" style="width: 593px; over-flow: auto"
									ref="editor" @on-change="handleEditorChange" />
								<!-- <span class="txt-style" @click="handleView()"
									v-show="uploadData.detail && uploadData.detail != '<p><br></p>'">预览</span> -->
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="附件上传" class="upload-item">
								<el-upload :class="{
									uploadFileSty: showBtnDealFile,
									disUploadFileSty: noneBtnFile,
								}" ref="uploadReport" class="upload-demo" drag :action="uploadFileUrl" :headers="headerObj"
									accept=".pdf, .doc, .docx, .xlsx, .xls, .xlsm, .xltx, .xltm, .csv, .jpg, .png"
									:multiple="false" :limit="1" :on-preview="handlePreviewReport"
									:on-change="handleChangeReport" :on-success="handleReportSuccess"
									:before-upload="beforeReportUpload" :file-list="reportFileList"
									v-loading="uploadLoading">
									<i class="el-icon-upload"></i>
									<div class="el-upload__text">
										<em>点击</em>
										或将文件拖拽到这里上传
									</div>
									<div slot="file" slot-scope="{file}">
										<div class="file-upload-style" v-show="noneBtnFile"
											@click="handlePreviewReport(file)">
											<div class="file-img">
												<img :src="filePic" alt="" />
											</div>
											<span class="fille-name">{{ file.name }}</span>
											<i class="el-icon-close card-panel-icon icon-style"
												@click.stop="handleRemoveReport(file)" />
										</div>
									</div>
									<div class="el-upload__tip" slot="tip" v-show="!noneBtnFile">
										只能上传一份文件，支持pdf/doc/docx/xlsx/xls/csv/jpg/png格式
										<div>文件大小不超过50M</div>
									</div>
								</el-upload>
							</el-form-item>
						</el-col>

						<el-col>
							<el-form-item class="btn-item">
								<el-button @click="cancel()">取消</el-button>
								<el-button type="primary" @click="submitForm('uploadForm')"
									:loading="submitUploadLoading">确认</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</Modal>
		</div>
		<!-- 文档预览  dialog -->
		<div class="wai-view">
			<Modal :visible.sync="quillVisible" class="dialog-view-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">文档预览</div>
				<div class="report-preview ql-editor" id="detailPreview"></div>
			</Modal>
		</div>
		<!-- 新增分类   dialog -->
		<div class="wai-type">
			<Modal :visible.sync="typeVisible" @reset="resetTypeFields" class="dialog-type-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
					{{ typeTitle }}
				</div>
				<el-form :model="typeData" :rules="typeRules" ref="typeForm" label-width="100px" class="demo-ruleForm">
					<el-row>
						<el-col :span="24">
							<el-form-item label="" prop="folderName" class="title-item">
								<el-input v-model.trim="typeData.folderName" placeholder="请输入分类名称" maxlength="10"
									:show-word-limit="true"></el-input>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item class="btn-item">
								<el-button @click="cancelType()">取消</el-button>
								<el-button type="primary" @click="submitTypeForm('typeForm')"
									:loading="submitTypeLoading">确认</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</Modal>
		</div>
		<!-- 修改文件夹名称  dialog -->
		<div class="wai-type">
			<Modal :visible.sync="fileVisible" @reset="resetFileFields" class="dialog-type-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
					{{ fileTitle }}
				</div>
				<el-form :model="fileData" :rules="fileRules" ref="fileForm" label-width="100px" class="demo-ruleForm">
					<el-row>
						<el-col :span="24">
							<el-form-item label="" prop="folderName" class="title-item">
								<el-input v-model.trim="fileData.folderName" placeholder="请输入文件夹名称" maxlength="10"
									:show-word-limit="true"></el-input>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item class="btn-item">
								<el-button @click="cancelFile()">取消</el-button>
								<el-button type="primary" @click="submitFileForm('fileForm')"
									:loading="submitFileLoading">确认</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</Modal>
		</div>
		<!-- 富文本预览  dialog -->
		<div class="wai-view">
			<Modal :visible.sync="dtlVisible" class="dialog-view-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">文档预览</div>
				<div class="report-preview ql-editor" id="dtlPreview"></div>
				<div class="file-row" v-for="(item, index) in previewFileList" :key="index">
					<img :src="item.filePic" />
					<span class="mx-20 cursor-pointer">{{ item.name }}</span>
					<el-button  size="mini" type="primary" @click="toReadOrDownload(item)">查看并下载</el-button>
				</div>
			</Modal>
		</div>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showMemberBtn="showMemberBtn" :text="text">
		</ToPayOrMember>
		<el-dialog :visible.sync="visible" width="50%" class="dataDescriptionDialog">
			<img class="dataDescriptionImg" src="../../../assets/img/successful/dataDescription.png" alt="" />
		</el-dialog>
		<el-dialog title="提示" :visible.sync="isAddCustomerList" width="30%">
			<span>加入「客户列表」可对文档进行管理</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="isAddCustomerList = false">取 消</el-button>
				<el-button type="primary" @click="joinMyClient(1)">加 入</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="fileVisible" width="80%" :close-on-click-modal="false" center append-to-body :top="'0'">
			<div class="file-box">
				<h4 class="text-center mb-10">{{ item.fileName }}</h4>
				<div class="preview-box w-full overflow-auto mb-10" style="height: 600px;">
					<div v-if="item.fileExtension === 'zip' || item.fileExtension === 'rar' || item.fileExtension === '7z' ">
						<img :src="item.filePic" />
					</div>
					<iframe :src="kkFileUrl + '/onlinePreview?url=' + encodeURIComponent(Base64.encode(item.url))"  class="preview-iframe w-full" style="height: 100%;" v-else></iframe>
				</div>
				<div class="text-center">
					<el-button type="primary" @click="goDownload(item)">立即下载</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import Editor from "@/components/Editor";
// import { validateTax } from "@/utils/validate.js";
import ByTenderee from "./byTenderee";
import ByBidwinner from "./byBidwinner";
import PersonInfo from "./personInfo";
import TrackRecord from "./trackRecord";
import html2canvas from "html2canvas";
import NotepadModal from "@/components/notepadModal";
import RelationGraph from "relation-graph";
import ToPayOrMember from "@/components/ToPayOrMember";

import { mapGetters } from "vuex";
import { Base64 } from "js-base64";

export default {
	components: {
		Pagination,
		LoginModal,
		MemberModal,
		Empty,
		Modal,
		ByTenderee,
		ByBidwinner,
		NotepadModal,
		PersonInfo,
		TrackRecord,
		Editor,
		RelationGraph,
		ToPayOrMember,
	},
	data() {
		let price = (rule, value, callback) => {
			if (!value) {
				callback();
			} else {
				const reg = /^[0-9]{1,18}([.][0-9]{1,6})?$/;
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error("最多18位整数，6位小数"));
				}
			}
		};
		return {
			Base64: Base64,
			showMemberBtn: true,
			fileName: "",
			consumerId: "",
			delflag: false,
			pathUrl: "",
			pathName: "",
			graphOptions: {
				// allowShowMiniToolBar: false,
				allowShowMiniNameFilter: false,
				allowSwitchLineShape: false,
				allowSwitchJunctionPoint: false,
				defaultFocusRootNode: false,
				disableZoom: true,
				layouts: [
					{
						label: "自动布局",
						layoutName: "force",
						layoutClassName: "seeks-layout-force",
					},
				],
			},
			id: "",
			type: "",
			activeName: "navOne",
			mdsClientInfo: {},
			detail: {},
			clientBasic: "", // 工商信息
			mdsClientActualOwner: "",
			shareholderPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			shareholderTotal: 0,
			shareholderList: [], // 股东信息
			principalPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			principalTotal: 0,
			principalList: [], // 主要人员
			investmentPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			investmentTotal: 0,
			investmentList: [], // 对外投资
			controlsPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			controlsTotal: 0,
			controlsList: [], // 实际控制企业
			branchesPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			branchesTotal: 0,
			branchesList: [], // 分支机构

			showType: 1, // 1 展示图谱 2 展示表格
			conPO: {
				navIndex: 1, //1 招标人 2中标人
				tenderee: "",
				bidWinner: "",
				winnerPriceStart: "",
				winnerPriceEnd: "",
				dateStart: "",
				dateEnd: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
				defaultAccess: true,
			}, // 查询条件
			params: {},
			rules: {
				winnerPriceStart: [
					{
						validator: price,
						trigger: "blur",
					},
				],
				winnerPriceEnd: [
					{
						validator: price,
						trigger: "blur",
					},
				],
			},

			dynamicTotal: 0,
			dynamicStart: "",
			dynamicEnd: "",

			dynamicPO: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			pageDynamic: [],
			bidsStart: "",
			bidsEnd: "",
			bidInput: "",
			biddingTotal: 0,
			bidsPO: {
				platform: 1,
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			pageBidding: [],

			typeName: "",
			fileIndex: 0,
			clickIndex: 0,
			backIndex: 0,
			fileList: [],
			typeList: [],
			fileTable: [],
			fileTotal: 0,
			// 存储
			usedSize: 0,
			spaceSize: 0,
			filePO: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			folderId: "",
			// 以下弹窗字段
			uploadVisible: false,
			uploadTitle: "上传资料",
			uploadLoading: false,
			uploadData: {
				fileName: "",
			},
			uploadFileUrl: process.env.VUE_APP_BASE_URL + "/mds/interceptApi/consumerClient/uploadArchivesFile", // 上传PDF、WORD服务器地址
			headerObj: {
				Authorization: this.$session.getLoginToken(),
			},
			reportUrl: "",
			reportFileList: [],
			showBtnDealFile: true,
			noneBtnFile: false,
			filePic: "",
			uploadList: [],
			uploadRules: {
				fileName: [{ required: true, message: "请输入资料名称", trigger: "blur" }],
				detail: [
					{ required: true, message: "请输入富文本内容", trigger: "change" }
				],
				folderId: [{ required: true, message: "请选择所属分类", trigger: "change" }],
				// fileId: [{ required: true, message: "请上传文件", trigger: "change" }]
			},
			submitUploadLoading: false,
			recordVisible: false,
			recordTitle: "新建记录文档",
			recordData: {},
			recordRules: {
				fileName: [{ required: true, message: "请输入资料名称", trigger: "blur" }],
				folderId: [{ required: true, message: "请选择所属分类", trigger: "change" }],
				// detail: [
				//   { required: true, message: "请输入富文本内容", trigger: "change" }
				// ]
			},
			submitRecordLoading: false,
			typeVisible: false,
			typeTitle: "新增分类",
			typeData: {},
			typeRules: {
				folderName: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
			},
			submitTypeLoading: false,
			fileVisible: false,
			fileTitle: "修改文件夹名称",
			fileData: {},
			fileRules: {
				folderName: [{ required: true, message: "请输入文件夹名称", trigger: "blur" }],
			},
			submitFileLoading: false,
			quillVisible: false,
			hoverFlag: false,
			dtlVisible: false,
			isTime: false,
			isSensitive: false,
			orderPO: {},
			memberId: null,
			text: "",
			minDate: "",
			maxDate: "",
			minDateDynamic: "",
			maxDateDynamic: "",
			minDateBids: "",
			maxDateBids: "",
			pageBiddingWinner: [], //中标动态
			visible: false, //数据说明
			title: "数据说明",
			isBidConsortium: false,
			addType: false,
			isAddCustomerList: false, // 0  未加入客户列表  1加入了客户列表
			downloadButtonShow: null,
			previewFileList: [],//预览文件列表
			item:{},
		};
	},
	computed: {
		...mapGetters(["kkFileUrl"]),
		startTime() {
			return {
				disabledDate: time => {
					let disableTime;
					if (this.conPO.dateEnd) {
						disableTime = time.getTime() > new Date(this.conPO.dateEnd).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						disableTime = time > this.maxDate || time < this.minDate;
					}

					return disableTime;
				},
			};
		},
		endTime() {
			return {
				disabledDate: time => {
					if (this.conPO.dateStart) {
						return time.getTime() < new Date(this.conPO.dateStart).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
		dynamicStartTime() {
			return {
				disabledDate: time => {
					let disableTime;
					if (this.dynamicEnd) {
						disableTime = time.getTime() > new Date(this.dynamicEnd).getTime() || time > this.maxDateDynamic || time < this.minDateDynamic;
					} else {
						disableTime = time > this.maxDateDynamic || time < this.minDateDynamic;
					}

					return disableTime;
				},
			};
		},
		dynamicEndTime() {
			return {
				disabledDate: time => {
					if (this.dynamicStart) {
						return time.getTime() < new Date(this.dynamicStart).getTime() || time > this.maxDateDynamic || time < this.minDateDynamic;
					} else {
						return time > this.maxDateDynamic || time < this.minDateDynamic;
					}
				},
			};
		},
		bidsStartTime() {
			return {
				disabledDate: time => {
					let disableTime;
					if (this.bidsEnd) {
						disableTime = time.getTime() > new Date(this.bidsEnd).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						disableTime = time > this.maxDate || time < this.minDate;
					}

					return disableTime;
				},
			};
		},
		bidsEndTime() {
			return {
				disabledDate: time => {
					if (this.bidsStart) {
						return time.getTime() < new Date(this.bidsStart).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
	},
	created() {
		this.consumerId = this.$session.getUsers().consumerId;
		this.memberId = this.$session.getUsers().memberId;
	},
	mounted() {
		if (this.$route.query.pathType == 1) {
			this.pathName = "我的订阅";
			this.pathUrl = "/user/my-collect/subscription";
		} else if (this.$route.query.pathType == 2) {
			this.pathName = "我的客户";
			this.pathUrl = "/user/my-collect/customer";
		}
		let id = this.$route.query.id;
		this.id = id;

		let type = this.$route.query.type;
		this.type = type;
		this.getClientInfoDetail();
		if (type == 1 && this.memberId != 7 && this.memberId != 4) {
			// 企业客户
			this.getClientInfoDetailShareholder();
			this.getClientInfoDetailPrincipalStaff();
			this.getClientInfoDetailInvestment();
			this.getClientInfoDetailActualControls();
			this.getClientInfoDetailBranches();
		} else {
			// 非企业客户
			this.activeName = "navFour";
			// this.$refs.personInfo.getClientStaff();
		}
		//获取时间限制
		this.getTimeSelectorHistory();
		this.getTimeSelector();
		this.$nextTick(() => {
			if (this.$route.query.dynamic == 1) {
				this.activeName = "navThree";
			} else if (this.$route.query.dynamic == 2) {
				this.activeName = "navSix";
			}
		});
		// 数据收费订单预览
		if (this.$route.query.orderId) {
			this.activeName = "navFour";
			// 获取数据收费订单查询参数
			this.$api.personalCenter
				.getDataPreview({
					orderId: this.$route.query.orderId,
				})
				.then(res => {
					let dataDesc = JSON.parse(res.data.dataDesc);
					dataDesc.params.dateStart = dataDesc.params.dateStart.slice(0, 7);
					dataDesc.params.dateEnd = dataDesc.params.dateEnd.slice(0, 7);
					this.orderPO = dataDesc.params;
					if (dataDesc.dataType == 1) {
						// 按招标人
						this.activeName = "navFour";
						this.conPO.navIndex = 1;
						this.$refs.byTenderee.getParams(dataDesc.params, 1);
					} else {
						// 按中标人
						this.activeName = "navFour";
						this.conPO.navIndex = 2;
						this.$refs.byBidwinner.getParams(dataDesc.params, 1);
					}
				});
		}
		this.getDownloadButtonShow();
	},
	methods: {
		// 点击下载
		toReadOrDownload(item) {
			this.item=item
			this.fileVisible = true;
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename);
			} else {
				const anchor = document.createElement("a");
				const body = document.querySelector("body");
				anchor.href = window.URL.createObjectURL(blob);
				anchor.download = filename;
				console.log(filename, "filename");
				anchor.style.display = "none";
				body.appendChild(anchor);
				anchor.click();
				body.removeChild(anchor);
				window.URL.revokeObjectURL(anchor.href);
			}
			//存储下载记录
			let dataParams = {
				// localFileUrl: savedFilePath,
				relationId: this.item.shareId,
				consumerId: this.$session.getUsers().consumerId,
				relationType: "60611011",
			};
			console.log("联网打开成功,下载记录 ", dataParams);
			//打开文件同时存储下载记录
			this.$api.viparea.shareDownloadFile(dataParams);
		},
		getBlob(url) {
			return new Promise(resolve => {
				const xhr = new XMLHttpRequest();
				// 避免 200 from disk cache
				url = url + `?r=${Math.random()}`;
				xhr.open("GET", url, true);
				xhr.responseType = "blob";
				xhr.onload = () => {
					if (xhr.status === 200) {
						resolve(xhr.response);
					}
				};
				xhr.send();
			});
		},
		async fileDownload(url, newFilename) {
			const blob = await this.getBlob(url);
			console.log(blob, "blob");
			this.saveAs(blob, newFilename);
		},
		goDownload(item) {
			const loading = this.$loading({
				lock: true,
				text: "下载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.fileDownload(item.url, item.name);
			loading.close();
		},
		// 获取下载按钮是否显示
		getDownloadButtonShow() {
			let params = {
				childModuleCode: 10071001,
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShow = res.data.downloadButtonShow == "10011001" ? true : false;
			});
		},
		// 获取是否加入客户列表)
		getMyClient() {
			this.$api.industry
				.isMyClient({
					clientId: this.id,
				})
				.then(res => {
					console.log("res22", res);
					if (res.data == 1) {
						this.addType = true;
					} else {
						this.addType = false;
					}
					this.isAddCustomerList = !(this.addType)
				});
		},
		// 数据说明
		openDataDescription() {
			this.visible = true;
		},
		getTimeSelector() {
			this.$api.industry.getTimeSelector().then(res => {
				this.minDateDynamic = new Date(res.data.minDateTime + " 00:00:00");
				this.maxDateDynamic = new Date(res.data.maxDateTime + ` 23:59:59`);
			});
		},
		getTimeSelectorHistory() {
			return new Promise((resolve, reject) => {
				this.$api.industry
					.getTimeSelectorHistory()
					.then(res => {
						// this.timeLimit = res.data
						this.minDate = new Date(res.data.minDateTime + " 00:00:00");
						this.maxDate = new Date(res.data.maxDateTime + ` 23:59:59`);
						this.conPO.dateEnd = res.data.maxDateTime;
						let year = this.conPO.dateEnd.split("-")[0];
						let month = this.conPO.dateEnd.split("-")[1];
						console.log(year, month);
						this.conPO.dateEnd = `${year}-${month}`;
						if (month < 3) {
							year = year - 1;
							month = Number(month) + 10;
						} else {
							month = Number(month) - 2;
						}
						month = month < 10 ? "0" + month : month;
						this.conPO.dateStart = `${year}-${month}`;
						resolve();
						// console.log('this.conPO',this.conPO)
						// this.$refs.byTenderee.init(this.conPO);
					})
					.finally(() => {
						resolve();
					});
			});
		},
		getIsSensitive(isSensitive) {
			this.isSensitive = isSensitive;
		},
		async clickNav(type) {
			this.reset();
			await this.getTimeSelectorHistory();
			let { conPO, mdsClientInfo, id } = this;
			conPO.clientId = id;
			conPO.navIndex = type;
			this.isBidConsortium = false;
			if (type == 1) {
				conPO.tenderee = mdsClientInfo.clientName;
				this.$refs.byTenderee.getParams(conPO, 1);
			} else {
				conPO.bidWinner = mdsClientInfo.clientName;
				this.$refs.byBidwinner.getParams(conPO, 1);
			}
			this.conPO = conPO;
		},
		reset() {
			this.conPO.navIndex = 1;
			this.conPO.tenderee = "";
			this.conPO.bidWinner = "";
			this.conPO.winnerPriceStart = "";
			this.conPO.winnerPriceEnd = "";
			this.conPO.dateStart = "";
			this.conPO.dateEnd = "";
			this.conPO.pageSize = 10; //页数
			this.conPO.pageNum = 1;
			this.conPO.defaultAccess = true;
			this.showType = 1;
		},
		coloring() {
			let colors = ["#90cfb7", "#8ca3cc", "#e8ab6f", "#6daae2", "#c8b7d8"];
			let index = Math.floor(Math.random() * 5);
			return colors[index];
		},
		// 判断经营状态
		getNewStatus(type) {
			if (type == 1) {
				return "存续";
			} else if (type == 2) {
				return "注销";
			} else if (type == 3) {
				return "吊销";
			} else if (type == 4) {
				return "撤销";
			} else if (type == 5) {
				return "迁出";
			} else if (type == 6) {
				return "设立中";
			} else if (type == 7) {
				return "清算中";
			} else if (type == 8) {
				return "停业";
			} else if (type == 9) {
				return "其他";
			}
		},
		toMember() {
			let url = this.$router.resolve({
				path: `/member`,
			});
			window.open(url.href, "_blank");
		},
		// 切换tab栏前
		beforeleaveNav(activeName) {
			if (activeName == "navTwo") {
				this.getClientInfoDynamic();
			} else if (activeName == "navThree") {
				const loading = this.$loading({
					lock: true,
					text: "加载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: true,
				});
				this.$api.industry
					.getClientInfoDetail({
						clientId: this.id,
					})
					.then(res => {
						loading.close();
						this.detail.clientName = res.data.mdsClientInfo.clientName;
						this.getClientInfoTenderInfo();
					})
					.catch(msg => {
						loading.close();
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else if (activeName == "navFour") {
				let { conPO, detail, id } = this;
				conPO.clientId = id;
				conPO.tenderee = detail.clientName;
				if (!this.$route.query.orderId) {
					this.$refs.byTenderee.getParams(conPO, 1);
				}
			} else if (activeName == "navFive") {
				this.getClientInfoArchivesFolder();
				this.getMyClient();
			} else if (activeName == "personInfo") {
				this.$refs.personInfo.getClientStaff();
			} else if (activeName == "trackRecord") {
				this.$refs.trackRecord.getClientProject();
			} else if (activeName == "navSix") {
				this.bidsEnd = "";
				this.bidsStart = "";
				this.bidInput = "";
				this.getFindPageForClient();
			}
		},
		// 获取基本信息
		getClientInfoDetail() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: true,
			});
			this.$api.industry
				.getClientInfoDetail({
					clientId: this.id,
				})
				.then(res => {
					let data = res.data;

					if (!data.mdsClientInfo.logoUrl) {
						this.$api.industry
							.getCompanyName({
								companyName: data.mdsClientInfo.clientName,
							})
							.then(ress => {
								data.mdsClientInfo.logoImg = ress.msg;
								// data.mdsClientInfo.bcColor = this.coloring();
								this.$forceUpdate();
							})
							.catch(msg => {
								if (msg?.msg) {
									this.$message.error(msg?.msg);
								}
							});
					}
					this.mdsClientInfo = data.mdsClientInfo;
					this.detail = data.mdsClientInfo;
					if (data.clientBasic) {
						this.clientBasic = data.clientBasic;
					}
					if (data.mdsClientActualOwner) {
						let owner = data.mdsClientActualOwner;
						this.mdsClientActualOwner = owner;
						let nodes = JSON.parse(owner.nodes);
						let links = JSON.parse(owner.links);
						nodes.forEach(item => {
							item.text = item.name;
							item.color = "#1181FA";
							item.fontColor = "#fff";
							item.nodeShape = 1;
							item.borderColor = "transparent";
							delete item.flated;
							delete item.isKey;
							delete item.name;
							delete item.type;
							delete item.uid;
						});
						links.forEach(item => {
							item.text = item.properties[0].relationPercent;
							item.from = item.sourceId + "";
							item.to = item.targetId + "";
							item.lineWidth = 2;
							delete item.properties;
							delete item.sourceId;
							delete item.targetId;
							delete item.type;
						});

						let __graph_json_data = {
							rootId: owner.actualOwnerId,
							nodes,
							links,
						};
						console.log(__graph_json_data);
						this.$refs.seeksRelationGraph.setJsonData(__graph_json_data);
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 获取股东信息
		getClientInfoDetailShareholder() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let { shareholderPo, id } = this;
			shareholderPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailShareholder(shareholderPo)
				.then(res => {
					let shareholderList = res.rows;
					shareholderList.forEach((item, index) => {
						item.index = (shareholderPo.pageNum - 1) * shareholderPo.pageSize + index + 1;
					});
					this.shareholderList = shareholderList;
					this.shareholderTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 股东信息分页
		shareholderPaginChange(page, pageSize) {
			this.shareholderPo.pageNum = page;
			this.shareholderPo.pageSize = pageSize;
			this.getClientInfoDetailShareholder();
		},
		// 获取主要人员
		getClientInfoDetailPrincipalStaff() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let { principalPo, id } = this;
			principalPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailPrincipalStaff(principalPo)
				.then(res => {
					let principalList = res.rows;
					principalList.forEach((item, index) => {
						item.index = (principalPo.pageNum - 1) * principalPo.pageSize + index + 1;
					});
					this.principalList = principalList;
					this.principalTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 主要人员分页
		principalPaginChange(page, pageSize) {
			this.principalPo.pageNum = page;
			this.principalPo.pageSize = pageSize;
			this.getClientInfoDetailPrincipalStaff();
		},
		// 获取企业对外投资
		getClientInfoDetailInvestment() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let { investmentPo, id } = this;
			investmentPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailInvestment(investmentPo)
				.then(res => {
					let investmentList = res.rows;
					investmentList.forEach((item, index) => {
						item.index = (investmentPo.pageNum - 1) * investmentPo.pageSize + index + 1;
					});
					this.investmentList = investmentList;
					this.investmentTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 企业对外投资分页
		investmentPaginChange(page, pageSize) {
			this.investmentPo.pageNum = page;
			this.investmentPo.pageSize = pageSize;
			this.getClientInfoDetailInvestment();
		},
		// 获取实际控制企业
		getClientInfoDetailActualControls() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let { controlsPo, id } = this;
			controlsPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailActualControls(controlsPo)
				.then(res => {
					let controlsList = res.rows;
					controlsList.forEach((item, index) => {
						item.index = (controlsPo.pageNum - 1) * controlsPo.pageSize + index + 1;
					});
					this.controlsList = controlsList;
					this.controlsTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 实际控制企业分页
		controlsPaginChange(page, pageSize) {
			this.controlsPo.pageNum = page;
			this.controlsPo.pageSize = pageSize;
			this.getClientInfoDetailActualControls();
		},
		// 获取分支机构
		getClientInfoDetailBranches() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let { branchesPo, id } = this;
			branchesPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailBranches(branchesPo)
				.then(res => {
					let branchesList = res.rows;
					branchesList.forEach((item, index) => {
						item.index = (branchesPo.pageNum - 1) * branchesPo.pageSize + index + 1;
					});
					this.branchesList = branchesList;
					this.branchesTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 分支机构分页
		branchesPaginChange(page, pageSize) {
			this.branchesPo.pageNum = page;
			this.branchesPo.pageSize = pageSize;
			this.getClientInfoDetailBranches();
		},
		// 点击记事本
		toNotepad() {
			this.$api.industry
				.getClientInfoNotePad({
					clientId: this.id,
				})
				.then(res => {
					this.$refs.notepadModal.list = res.data;
					this.$refs.notepadModal.content = "";
					this.$refs.notepadModal.activeName = "navOne";
					this.$refs.notepadModal.id = this.id;
					this.$refs.notepadModal.visible = true;
					this.$refs.notepadModal.editType = false;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 去官网
		goWeb(val) {
			this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				window.open(val, "_blank");
			});
		},
		// 加入-移除客户列表
		joinMyClient(type) {
			this.$api.industry
				.joinMyClient({
					clientId: this.id,
				})
				.then(() => {
					if (type == 1) {
						this.$message.success("已加入客户列表");
						this.mdsClientInfo.isJoin = 1;
						// this.$router.replace(`/client/consumer-detail?id=${this.id}&type=${this.type}&pathType=2`);
					}
					if (type == 0) {
						this.$message.success("已从客户列表移除");
						this.mdsClientInfo.isJoin = 0;

						// this.$router.replace(`/client/client-detail?id=${this.id}&type=${this.type}`);
						// this.detail.isJoin = 0;
					}
					this.getMyClient();
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 订阅-取消订阅
		jsubscribeClient(type) {
			this.$api.industry
				.jsubscribeClient({
					clientId: this.id,
				})
				.then(() => {
					if (type == 1) {
						this.$message.success("加入关注成功");
						this.detail.isJsubscribe = 1;
					} else {
						this.$message.success("已取消关注");
						this.detail.isJsubscribe = 0;
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},

		// 客户动态搜索
		dynamicQuery() {
			this.dynamicPO.pageNum = 1;
			this.dynamicPO.pageSize = 10;
			this.getClientInfoDynamic();
		},
		// 获取中标动态
		getFindPageForClient() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: true,
			});
			let { bidsPO, bidsStart, bidsEnd, mdsClientInfo, bidInput } = this;
			bidsPO.bidWinner = mdsClientInfo.clientName;
			bidsPO.dateStart = bidsStart;
			bidsPO.dateEnd = bidsEnd;
			bidsPO.search = bidInput;
			this.$api.industry
				.getFindPageForClient(bidsPO)
				.then(res => {
					loading.close();
					this.pageBiddingWinner = res.rows;
					this.biddingTotal = res.total;
				})
				.catch(msg => {
					loading.close();
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取客户动态
		getClientInfoDynamic() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: true,
			});
			let { dynamicPO, dynamicStart, dynamicEnd, id } = this;
			dynamicPO.clientId = id;
			dynamicPO.dateStart = dynamicStart;
			dynamicPO.dateEnd = dynamicEnd;
			this.$api.industry
				.getClientInfoDynamic(dynamicPO)
				.then(res => {
					loading.close();
					this.pageDynamic = res.rows;
					this.dynamicTotal = res.total;
				})
				.catch(msg => {
					loading.close();
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 客户动态分页
		dynamicPaginChange(page, pageSize) {
			this.dynamicPO.pageNum = page;
			this.dynamicPO.pageSize = pageSize;
			this.getClientInfoDynamic();
		},
		// 点击进入客户动态详情
		toDynamicDetail(id) {
			let url = this.$router.resolve({
				path: `/industry/dynamic-detail?id=${id}&clientId=${this.id}`,
			});
			window.open(url.href, "_blank");
		},
		// 招标动态搜索
		bidsQuery() {
			this.bidsPO.pageNum = 1;
			this.bidsPO.pageSize = 10;
			if (this.activeName == "navThree") {
				this.getClientInfoTenderInfo();
			} else if (this.activeName == "navSix") {
				this.getFindPageForClient();
			}
		},
		// 获取招标动态
		getClientInfoTenderInfo() {
			let { bidsPO, bidsStart, bidsEnd, detail, bidInput } = this;
			bidsPO.tenderee = detail.clientName;
			bidsPO.dateStart = bidsStart;
			bidsPO.dateEnd = bidsEnd;
			bidsPO.search = bidInput;
			this.$api.industry
				.getClientInfoTenderInfo(bidsPO)
				.then(res => {
					this.pageBidding = res.rows;
					this.biddingTotal = res.total;
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 招标动态分页
		biddingPaginChange(page, pageSize) {
			this.bidsPO.pageNum = page;
			this.bidsPO.pageSize = pageSize;
			if (this.activeName == "navThree") {
				this.getClientInfoTenderInfo();
			} else if (this.activeName == "navSix") {
				this.getFindPageForClient();
			}
		},
		// 点击进入招标动态详情
		toBidsDetail(item) {
			let url = this.$router.resolve({
				path: `/industry/bidding-detail?id=${item.tenderId}`,
			});
			window.open(url.href, "_blank");
		},
		// 点击进入中标动态详情
		toSuccessfulBidsDetail(item) {
			let url = this.$router.resolve({
				path: `/project/bidding-project-detail?id=${item.id}`,
			});
			window.open(url.href, "_blank");
		},
		toIndividual(scope) {
			console.log(scope);
		},
		paginChange(pageNum, pageSize) {
			this.filePO.pageNum = pageNum;
			this.filePO.pageSize = pageSize;
			this.getClientInfoArchivesFile(this.folderId);
		},
		// 点击表格展示或图谱展示
		clickShowType(type) {
			if (!this.params) {
				// 没点搜索时
				return;
			}
			let { conPO, mdsClientInfo, id } = this;
			conPO.clientId = id;
			conPO.moduleCode = 1007;
			conPO.childModuleCode = 10071001;
			conPO.isHistoryPro = true;
			this.showType = type;
			if (conPO.navIndex == 1) {
				conPO.tenderee = mdsClientInfo.clientName;
				console.log(this.orderPO);
				if (this.orderPO) {
					conPO = { ...conPO, ...this.orderPO };
				}
				if (this.isBidConsortium) {
					conPO.bidConsortium = mdsClientInfo.clientName;
				} else {
					conPO.bidConsortium = "";
				}
				if (type == 1) {
					this.$refs.byTenderee.getParams(conPO, 1);
				} else {
					this.$refs.byTenderee.getParams(conPO, 2);
				}
			} else {
				conPO.bidWinner = mdsClientInfo.clientName;
				if (this.orderPO) {
					conPO = { ...conPO, ...this.orderPO };
				}
				if (this.isBidConsortium) {
					conPO.bidConsortium = mdsClientInfo.clientName;
				} else {
					conPO.bidConsortium = "";
				}
				if (type == 1) {
					this.$refs.byBidwinner.getParams(conPO, 1);
				} else {
					this.$refs.byBidwinner.getParams(conPO, 2);
				}
			}
		},
		// 下载
		toDownload() {
			// if (!this.params) {
			//   // 没点搜索时
			//   return;
			// }
			// this.$message.error("暂无下载权限");
			// 判断用户是否登录
			const loading = this.$loading({
				lock: true,
				text: "下载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			if (this.$session.getUsers()) {
				let params = {
					moduleCode: 1007,
					childModuleCode: 10071001,
					type: 1,
				};
				this.$api.industry
					.getMemberPermission(params)
					.then(res => {
						console.log("res", res);
						if (res.data > 0) {
							this.openDownload(res.data);
						} else if (res.data < 0) {
							this.openDownload(res.data);
						} else {
							if (this.memberId == 3) {
								this.text = "您的次数已用完，升级VIP会员";
								this.$refs.toPayOrMember.openDialog();
							} else if (this.memberId == 1) {
								this.text = "您的次数已用完！";
								this.$refs.toPayOrMember.openDialog();
							} else if (this.memberId == 7) {
								this.$refs.toPayOrMember.openDialog();
							}
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		openDownload(tiems) {
			if (tiems > 0) {
				this.$confirm(`您还剩余${tiems}次下载机会，是否消耗1次？`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.download();
				});
			} else if (tiems < 0) {
				this.download();
			} else {
			}
		},
		download() {
			let { conPO, showType, orderPO } = this;
			console.log("conPO", conPO, orderPO);
			let paramsObj = JSON.parse(JSON.stringify(conPO));

			delete paramsObj.pageNum;
			delete paramsObj.pageSize;
			if (this.isBidConsortium) {
				paramsObj.bidConsortium = this.mdsClientInfo.clientName;
			} else {
				paramsObj.bidConsortium = "";
			}
			console.log("paramsObj1", paramsObj);
			// if (orderPO) {
			// 	paramsObj = JSON.parse(JSON.stringify(orderPO));
			// }
			console.log("paramsObj", paramsObj);
			if (showType == 1) {
				// 图谱下载
				if (conPO.navIndex == 1) {
					if (this.$refs.byTenderee.$refs.mind.isData) {
						this.exportImg(document.getElementById("historyTenderee"), "历史项目数据-按招标人视角", "png");
					}
				} else {
					if (this.$refs.byBidwinner.$refs.mind.isData) {
						this.exportImg(document.getElementById("historyBidwinner"), "历史项目数据-按中标人视角", "png");
					}
				}
				this.$api.industry
					.exportHistoryPro(paramsObj)
					.then(res => { })
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => { });
			} else {
				if (conPO.navIndex == 1) {
					// 表格下载
					const loading = this.$loading({
						lock: true,
						text: "导出中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					this.$api.industry
						.exportHistoryPro(paramsObj)
						.then(res => {
							this.$utils.downloadFile(res, "历史项目数据-按招标人视角.xlsx");
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						})
						.finally(() => {
							loading.close();
						});
				}
				if (conPO.navIndex == 2) {
					// 表格下载
					const loading = this.$loading({
						lock: true,
						text: "导出中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					// this.$api.industry
					// 	.getParams(paramsObj)
					// 	.then(res => {
					// 		this.$api.industry
					// 			.exportStaBidWinnerByTendereeByStr({str:res.data})
					// 			.then(res => {
					// 				this.$utils.downloadFile(res, "历史项目数据-按中标人.xlsx");
					// 			})
					// 			.catch(msg => {
					// 				if (msg?.msg) {
					// 					this.$message.error(msg?.msg);
					// 				}
					// 			})
					// 			.finally(() => {
					// 				loading.close();
					// 			});
					// 	})
					// 	.catch(msg => {
					// 		if (msg?.msg) {
					// 			this.$message.error(msg?.msg);
					// 		}
					// 	})
					// 	.finally(() => {
					// 		loading.close();
					// 	});
					this.$api.industry
						.exportClientInfoByBidWinner(paramsObj)
						.then(res => {
							this.$utils.downloadFile(res, "历史项目数据-按中标人.xlsx");
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						})
						.finally(() => {
							loading.close();
						});
				}
			}
		},
		/*
		 * element  导出的元素
		 * filename 文件名
		 * ext      扩展文件名
		 */
		exportImg(element, filename, ext) {
			const loading = this.$loading({
				lock: true,
				text: "导出中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			html2canvas(element, {
				useCORS: true,
				height: element.scrollHeight,
				windowHeight: element.scrollHeight,
				width: element.width,
				windowWidth: element.width,
			}).then(canvas => {
				loading.close();
				if (navigator.msSaveBlob) {
					const blob = canvas.msToBlob(); // IE10+
					return navigator.msSaveBlob(blob, name);
				} else {
					const imageurl = canvas.toDataURL("image/png");
					const aLink = document.createElement("a"); // 创建a标签
					aLink.style.display = "none";
					aLink.href = imageurl;
					aLink.download = `${filename}.${ext}`; // 下载文件名
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); // 用完后移除元素
				}
			});
		},
		// 输入最低/最高金额后
		blurPrice(type) {
			let { winnerPriceStart, winnerPriceEnd } = this.conPO;
			if (type == "start") {
				if (+winnerPriceStart > +winnerPriceEnd && winnerPriceEnd != "") {
					this.$message.error("最低金额不能大于最高金额");
					this.conPO.winnerPriceStart = "";
				}
			} else {
				if (+winnerPriceEnd < +winnerPriceStart && winnerPriceStart != "") {
					this.$message.error("最高金额不能小于最低金额");
					this.conPO.winnerPriceEnd = "";
				}
			}
		},
		// 点击历史项目数据中的搜索
		handleQuery() {
			let userInfo = localStorage.getItem("LOGIN_USERS_KEY");
			console.log("userInfo", JSON.parse(userInfo));
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let params = {
					moduleCode: 1007,
					childModuleCode: 10071001,
					type: 2,
				};
				this.$api.industry
					.getMemberPermission(params)
					.then(res => {
						console.log("res", res);
						if (res.data != 0 && res.data > 0) {
							this.open(res.data);
						} else if (res.data < 0) {
							this.getParams();
						} else {
							console.log(11);
							this.text = "您的次数已用完，升级VIP会员";
							this.$refs.toPayOrMember.openDialog();
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		open(tiems) {
			this.$confirm(`您还剩余${tiems}次查阅机会，是否消耗1次？`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				this.conPO.defaultAccess = false;
				this.getParams();
			});
		},
		getParams() {
			this.$refs.rulesPrice.validate(valid => {
				if (valid) {
					let { conPO, mdsClientInfo, id } = this;
					console.log("conPO", conPO);
					conPO.clientId = id;
					let params = JSON.parse(JSON.stringify(conPO));
					this.params = params;
					this.showType = 1;
					delete params.pageSize;
					delete params.pageNum;
					if (conPO.navIndex == 1) {
						conPO.tenderee = mdsClientInfo.clientName;
						if (this.isBidConsortium) {
							params.bidConsortium = mdsClientInfo.clientName;
						} else {
							params.bidConsortium = "";
						}
						this.$refs.byTenderee.getParams(params, 1);
					} else {
						if (this.isBidConsortium) {
							params.bidConsortium = mdsClientInfo.clientName;
						} else {
							params.bidConsortium = "";
						}
						console.log(123456);
						conPO.bidWinner = mdsClientInfo.clientName;
						this.$refs.byBidwinner.getParams(params, 1);
					}
					this.conPO.defaultAccess = true;
				} else {
					return false;
				}
			});
		},

		selectFile(i, item) {
			this.clickIndex = i;
			this.backIndex = i;
			this.folderId = item.folderId;
			this.getClientInfoArchivesFile(this.folderId);
		},

		hoverFile(i) {
			// console.log(i);
			this.fileIndex = i;
		},

		// 资料报告库  进度条自定义文字
		format() {
			return `${this.usedSize}KB/${this.spaceSize}M`;
		},
		// 资料报告库  上传资料按钮
		handleUpload() {
			this.uploadVisible = true;
			this.uploadTitle = "上传资料";
			this.noneBtnFile = false;
			this.reportFileList = [];
			// this.fileName = "";
			this.uploadData = {
				fileName: "",
			};
		},
		// 资料报告库  新建记录文档按钮
		handleRecord() {
			this.recordVisible = true;
			this.recordTitle = "新建记录文档";
			this.typeData = {};
		},
		// 资料报告库  添加分类按钮
		handAddType() {
			this.typeVisible = true;
			this.typeTitle = "新增分类";
			this.resetTypeFields();
		},

		// 资料报告库  关闭弹窗时重置表单
		resetFields() {
			// this.fileName = "";
			this.uploadData = {};
			this.$refs.uploadForm.resetFields();
		},
		resetTypeFields() {
			this.typeData = {};
		},
		resetRecordFields() {
			this.recordData = {};
			this.$refs.recordForm.resetFields();
		},
		resetFileFields() {
			// this.$refs.fileForm.resetFields();
			this.fileData = {};
		},
		// 资料报告库  富文本编辑事件
		handleEditorChange(val) {
			this.uploadData.detail = val.html;
		},
		// 资料报告库  预览富文本
		handleView() {
			this.quillVisible = true;
			if (this.uploadData.detail == undefined) {
				this.uploadData.detail = "";
			}
			setTimeout(() => {
				document.getElementById("detailPreview").innerHTML = this.uploadData.detail;
			}, 100);
		},
		submitForm(formName) {
			this.submitRecordLoading = true;
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.uploadData.detail == "" || this.uploadData.detail == "<p><br></p>" || !this.uploadData.detail) {
						this.$message.error("请输入富文本内容");
						this.submitRecordLoading = false;
						return false;
					} else {
						this.$api.personalCenter
							.addClientArchivesFile({
								...this.uploadData,
								clientId: this.id,
								uploadWay: "60471001",
							})
							.then(res => {
								this.uploadVisible = false;
								this.$message.success("提交成功");
								this.getClientInfoArchivesFolder();
								this.submitRecordLoading = false;
							})
							.catch(msg => {
								if (msg?.msg) {
									this.$message.error(msg?.msg);
								}
								this.submitRecordLoading = false;
							});
					}
				} else {
					// this.$message.error("提交失败!");
					this.submitRecordLoading = false;
					return false;
				}
			});
		},
		submitTypeForm(formName) {
			this.submitTypeLoading = true;
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$api.personalCenter
						.addClientArchivesFolder({
							...this.typeData,
							clientId: this.id,
						})
						.then(res => {
							this.typeVisible = false;
							this.$message.success("提交成功");
							this.getClientInfoArchivesFolder();
							this.submitTypeLoading = false;
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
							this.submitTypeLoading = false;
						});
				} else {
					this.$message.error("提交失败!");
					return false;
				}
			});
		},
		submitFileForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$api.personalCenter
						.updateClientArchivesFolder({ ...this.fileData })
						.then(res => {
							this.fileVisible = false;
							this.$message.success("提交成功");
							this.getClientInfoArchivesFolder();
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				} else {
					this.$message.error("提交失败!");
					return false;
				}
			});
		},
		// 资料报告库  表单取消
		cancel() {
			this.uploadVisible = false;
			// this.$refs.uploadForm.clearValidate();
			this.resetFields();
			this.$refs.uploadForm.resetFields();
		},
		cancelRecord() {
			this.recordVisible = false;
			// this.$refs.recordForm.clearValidate();
			this.resetRecordFields();
			this.$refs.recordForm.resetFields();
		},
		cancelType() {
			this.typeVisible = false;
			this.$refs.typeForm.clearValidate("folderName");
			this.resetTypeFields();
		},
		cancelFile() {
			this.fileVisible = false;
			this.$refs.fileForm.clearValidate("folderName");
			this.resetFileFields();
		},
		// 资料分类搜索
		typeSearch() {
			this.fileList = [];
			this.fileIndex = 0;
			this.getClientInfoArchivesFolder();
		},
		// 资料报告库   获取左侧文件夹列表
		getClientInfoArchivesFolder() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: true,
			});
			this.$api.personalCenter
				.getClientInfoArchivesFolder({
					clientId: this.id,
					folderName: this.typeName,
				})
				.then(res => {
					this.fileList = res.data;
					this.fileList.forEach(item => {
						if (item.consumerId == this.consumerId) {
							item.delflag = true;
						} else {
							item.delflag = false;
						}
					});
					loading.close();
					if (res.data.length != 0) {
						this.clickIndex = this.backIndex;
						if (this.fileList[this.backIndex]) {
							this.folderId = this.fileList[this.backIndex].folderId;
							this.getClientInfoArchivesFile(this.fileList[this.backIndex].folderId);
						} else {
							this.clickIndex = 0;
							this.folderId = this.fileList[0].folderId;
							this.getClientInfoArchivesFile(this.fileList[0].folderId);
						}
					}
					this.getClientSpace();
				})
				.catch(msg => {
					loading.close();
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
			this.$api.personalCenter
				.myClientInfoArchivesFolder({
					clientId: this.id,
				})
				.then(res => {
					this.typeList = res.data;
				})
				.catch(msg => {
					loading.close();
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 资料报告库  编辑左侧文件夹
		handleEditFile(row) {
			this.fileVisible = true;
			this.fileTitle = "修改文件夹名称";
			this.fileData = JSON.parse(JSON.stringify(row));
		},
		// 资料报告库  删除左侧文件夹
		handleDelFile(val) {
			this.$confirm("确认删除该文件夹吗？", "提示", {
				confirmButtonText: "确认删除",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$api.personalCenter
						.deleteClientArchivesFolder({ folderId: val })
						.then(res => {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.getClientInfoArchivesFolder();
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				})
				.catch(() => { });
		},
		// 资料报告库  获取存储空间
		getClientSpace() {
			this.$api.personalCenter
				.getClientSpace()
				.then(res => {
					this.spaceSize = Number((res.data.spaceSize / 1024 / 1024).toFixed(2));
					this.usedSize = res.data.usedSize.replace('KB','')*1;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 资料报告库  获取右侧文件列表
		getClientInfoArchivesFile(val) {
			this.$api.personalCenter
				.getClientInfoArchivesFile({
					folderId: val || this.folderId,
					clientId: this.id,
					...this.filePO,
				})
				.then(res => {
					this.fileTable = res.data.data;
					this.fileTotal = res.data.total;
					this.fileTable.forEach(item => {
						if (item.consumerId == this.consumerId) {
							item.delflag = true;
						} else {
							item.delflag = false;
						}
					});
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 资料报告库  右侧文件查看
		handleViewFile(row) {
			// if (row.uploadWay == "60471001") {
			// 富文本
			this.previewFileList = []
			this.$api.personalCenter
				.getFileDtl(row.folderFileId)
				.then(res => {
					this.dtlVisible = true;
					setTimeout(() => {
						document.getElementById("dtlPreview").innerHTML = res.data.detail;
					}, 100);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			this.$api.personalCenter.getFileInfo({ fileId: row.fileId }).then((res) => {
				let obj = {
					url: `${process.env.VUE_APP_FILEURL}${res.data.fileDir}/${res.data.fileKey}`,
					name: res.data.fileName,
					fileExtension: res.data.fileExtension,
					filePic: require("@/assets/img/file/" + res.data.fileExtension.toLowerCase() + ".png")
				}
				this.previewFileList.push(obj);
			})
			// } else {
			// 	// 文件
			// 	const loading = this.$loading({
			// 		lock: true,
			// 		text: "导出中...",
			// 		spinner: "el-icon-loading",
			// 		background: "rgba(255, 255, 255, 0.3)",
			// 		fullscreen: false,
			// 	});
			// 	this.$api.industry
			// 		.getClientInfoDownload({folderFileId: row.folderFileId})
			// 		.then(res => {
			// 			this.$utils.downloadFile(res, row.fileName + "." + row.fileExtension);
			// 		})
			// 		.catch(msg => {
			// 			if (msg?.msg) {
			// 				this.$message.error(msg?.msg);
			// 			}
			// 		})
			// 		.finally(() => {
			// 			loading.close();
			// 		}); 
			// }
		},
		// 资料报告库  删除右侧文件
		handleDelFileDtl(row) {
			this.$confirm("确认该文件吗？", "提示", {
				confirmButtonText: "确认删除",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$api.personalCenter
						.deleteFile(row.folderFileId)
						.then(res => {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.getClientInfoArchivesFolder();
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				})
				.catch(() => { });
		},
		inputFileName(val) {
			// this.fileName = val
			this.uploadData.fileName = val;
			if (val) {
				setTimeout(() => {
					this.uploadData.fileName = val;
					this.$refs.uploadForm.clearValidate("fileName");
					this.$forceUpdate();
				});
			}
			this.$forceUpdate();
		},
		// 资料报告库  上传资料
		handleChangeReport(file, fileList) { },
		handleReportSuccess(res, file) {
			if (res.code == 200) {
				this.noneBtnFile = true;
				this.uploadLoading = false;
				this.$message.success("上传成功");
				if (!this.uploadData.fileName) {
					this.uploadData.fileName = res.data.fileName;
					setTimeout(() => {
						this.$refs.uploadForm.clearValidate("fileName");
						this.$forceUpdate();
					});
					// this.fileName = res.data.fileName;
				}
				this.$forceUpdate();
				this.uploadData.fileId = res.data.fileId;
				this.uploadData.fileSize = res.data.fileLength;
				this.$refs.uploadForm.clearValidate("fileId");
				this.reportUrl = res.data.url;
				this.filePic = require("@/assets/img/file/" + res.data.fileExtension.toLowerCase() + ".png");
			} else {
				this.$refs.uploadReport.handleRemove(file);
				this.noneBtnFile = false;
				this.uploadLoading = false;
				this.$message.error(res.msg);
			}
		},
		beforeReportUpload(file) {
			this.noneBtnFile = true;
			this.uploadLoading = true;
			let name = file.name;
			let namearr = name.split(".");
			let houzuiArr = ['pdf', 'doc', 'docx', 'xlsx', 'xls', 'csv', 'jpg', 'png']
			console.log(name)
			if (namearr.length > 2) {
				this.uploadLoading = false;
				this.$message.error("文件名称不能携带特殊字符！");
				this.noneBtnFile = false;
				return false;
			} else if (!houzuiArr.includes(namearr[1].toLowerCase())) {
				this.uploadLoading = false;
				this.$message.error("请上传pdf/doc/docx/xlsx/xls/csv/jpg/png格式文件！");
				this.noneBtnFile = false;
				return false;
			} else {
				let a = file.name.lastIndexOf(".");
				let d = file.name.toLowerCase().substring(a + 1, file.name.length);
				this.filePic = require("@/assets/img/file/" + d + ".png");
				const isLt50M = file.size / 1024 / 1024 < 50;
				if (!isLt50M) {
					this.uploadLoading = false;
					this.$message.error("上传文件大小不能超过50M!");
					this.noneBtnFile = false;
				}
				return isLt50M;
			}
		},
		handlePreviewReport(file) {
			window.open(this.reportUrl, "_blank");
		},
		handleRemoveReport(file, fileList) {
			this.$refs.uploadReport.handleRemove(file);
			this.uploadData.fileId = "";
			this.noneBtnFile = false;
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
