<template>
	<div class="by-bidwinner">
		<div class="result-table-box" v-show="showType == 2">
			<div class="empty-box" v-show="!isData">
				<empty :name="'暂无数据'"></empty>
			</div>
			<div v-show="isData">
				<div class="table-box">
					<div class="title-box">
						<div class="icon-left"></div>
						<div class="title-name">招标单位</div>
						<div class="icon-right"></div>
					</div>
					<el-table :data="winnerList" header-cell-class-name="table_header" style="width: 100%" max-height="472" show-summary>
						<el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
						<el-table-column prop="bidWinner" label="中标单位" align="center"></el-table-column>
						<el-table-column prop="tenderee" label="招标单位" align="center"></el-table-column>
						<el-table-column prop="projectAllCount" :formatter="messageformate" label="项目数量（个）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader"></el-table-column>
						<el-table-column prop="projectAllPrice" :formatter="messageformate1" label="中标金额（亿元）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader"></el-table-column>
						<el-table-column v-if="!isSensitive" align="center" label="查看" width="80">
							<template slot-scope="scope">
								<div class="btn-detail" @click="toDetail(scope.row)">详情</div>
							</template>
						</el-table-column>
					</el-table>
					<!-- <div class="pagination-box">
            <pagination
              ref="paginationOne"
              :total="winnerTotal"
              @change="winnerPaginChange"
              v-if="winnerTotal != 0"
            ></pagination>
          </div> -->
				</div>
				<div class="table-box table-area">
					<div class="title-box">
						<div class="icon-left"></div>
						<div class="title-name">地区</div>
						<div class="icon-right"></div>
					</div>
					<el-table :data="areaList" header-cell-class-name="table_header" style="width: 100%" max-height="472" show-summary>
						<el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
						<el-table-column prop="bidWinner" label="中标单位" align="center"></el-table-column>
						<el-table-column prop="province" label="省级" width="110" align="center"></el-table-column>
						<el-table-column prop="city" label="市级" width="110" align="center"></el-table-column>
						<el-table-column prop="projectCounty" label="区县级" width="110" align="center"></el-table-column>
						<el-table-column prop="projectAllCount" :formatter="messageformate" label="项目数量（个）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader"></el-table-column>
						<el-table-column prop="projectAllPrice" :formatter="messageformate1" label="中标金额（亿元）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader"></el-table-column>
						<el-table-column v-if="!isSensitive" align="center" label="查看" width="80">
							<template slot-scope="scope">
								<div class="btn-detail" @click="toDetail(scope.row)">详情</div>
							</template>
						</el-table-column>
					</el-table>
					<!-- <div class="pagination-box">
            <pagination
              ref="paginationTwo"
              :total="areaTotal"
              @change="areaPaginChange"
              v-if="areaTotal != 0"
            ></pagination>
          </div> -->
				</div>
				<div class="table-box table-type">
					<div class="title-box">
						<div class="icon-left"></div>
						<div class="title-name">项目类型</div>
						<div class="icon-right"></div>
					</div>
					<el-table :data="typeList" header-cell-class-name="table_header" style="width: 100%" max-height="472" show-summary>
						<el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
						<el-table-column prop="bidWinner" label="中标单位" align="center"></el-table-column>
						<el-table-column prop="projectType" label=" 项目类型" align="center"></el-table-column>
						<el-table-column prop="projectAllCount" :formatter="messageformate" label="项目数量（个）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader"></el-table-column>
						<el-table-column prop="projectAllPrice" :formatter="messageformate1" label="中标金额（亿元）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader"></el-table-column>
						<el-table-column v-if="!isSensitive" align="center" label="查看" width="80">
							<template slot-scope="scope">
								<div class="btn-detail" @click="toDetail(scope.row)">详情</div>
							</template>
						</el-table-column>
					</el-table>
					<!-- <div class="pagination-box">
            <pagination
              ref="paginationThree"
              :total="typeTotal"
              @change="typePaginChange"
              v-if="typeTotal != 0"
            ></pagination>
          </div> -->
				</div>
			</div>
		</div>
		<div class="result-mind-box" v-show="showType == 1">
			<by-mind ref="mind" @isSensitive="getIsSensitive"></by-mind>
		</div>
		<member-modal ref="memberModal"></member-modal>
		<project-modal ref="projectModal"></project-modal>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import ByMind from "./byMind";
import Empty from "@/components/Empty";
import axios from "axios";
import MemberModal from "@/components/MemberModal";
import html2canvas from "html2canvas";
import projectModal from "../projectModal/index.vue";
export default {
	components: {
		Pagination,
		ByMind,
		Empty,
    MemberModal,
		projectModal,
	},
	data() {
		return {
			showType: 1, // 1 展示图谱 2 展示表格
			isData: false,
			winnerList: [],
			areaList: [],
			typeList: [],
			// winnerParams: {},
			// areaParams: {},
			// typeParams: {},
			// winnerTotal: 0,
			// areaTotal: 0,
			// typeTotal: 0,
			params: "",
			isTime: false,
			isSensitive: false,
		};
	},
	mounted() {
	},
	methods: {
		getIsSensitive(isSensitive) {
			this.isSensitive = isSensitive;
		},
		messageformate(row, column) {
			if (row.projectAllCount) {
				return row.projectAllCount;
			} else {
				return row.projectAllCountPrivate;
			}
		},
		messageformate1(row, column) {
			if (row.projectAllPrice) {
				return row.projectAllPrice;
			} else {
				return row.projectAllPricePrivate;
			}
		},
		renderHeader(h, {column, $index}) {
			if (column.order === "ascending") {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #1181FA;' class='iconfont icon-shengxu'></i>
					</div>
				);
			} else if (column.order === "descending") {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #1181FA;' class='iconfont icon-jiangxu'></i>
					</div>
				);
			} else {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #fff;' class='iconfont icon-jiangxu'></i>
					</div>
				);
			}
		},
		// 初始化搜索数据
		getParams(params, showType) {
			this.showType = showType;
			this.params = params;
			if (this.showType == 1) {
				this.$refs.mind.getParams(params);
			} else {
				this.getTableData(params);
			}
		},
		// 获取表格数据
		getTableData(params) {
			// let winnerParams = JSON.parse(JSON.stringify(params));
			// this.winnerParams = winnerParams;
			// let areaParams = JSON.parse(JSON.stringify(params));
			// this.areaParams = areaParams;
			// let typeParams = JSON.parse(JSON.stringify(params));
			// this.typeParams = typeParams;
			const loading = this.$loading({
				lock: true,
				text: "搜索中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			axios
				.all([this.$api.successful.getStaBidWinnerSwitch(params)])
				.then(
					axios.spread(res => {
						if (res.data.staBidWinnerByTenderee.length != 0 && res.data.staBidWinnerByArea.length != 0 && res.data.staBidWinnerByProType.length != 0) {
							this.isData = true;
							this.winnerList = res.data.staBidWinnerByTenderee;
							this.areaList = res.data.staBidWinnerByArea;
							this.typeList = res.data.staBidWinnerByProType;
							this.isSensitive = res.data.isSensitive || false;
							if (params.dateStart || params.dateEnd) {
								this.isTime = false;
							} else {
								this.isTime = true;
							}
						} else {
							this.winnerList = [];
							this.areaList = [];
							this.typeList = [];
							this.isData = false;
							this.isTime = false;
						}
					})
				)
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 点击表格展示或图谱展示
		clickShowType(type) {
      if (this.$parent.orderPO) {
        this.showType = type;
        if (type == 1) {
          this.$refs.mind.getParams(this.$parent.orderPO, "切换");
        } else {
          this.getTableData(this.$parent.orderPO);
        }
      } else {
        if (!this.params) {
          // 没点搜索时
          return;
        }
        let conPO = this.$parent.conPO;
        conPO.moduleCode = 1002;
        conPO.childModuleCode = 10021002;
        if (!conPO.bidWinner) {
          this.$message.error("请输入中标人");
          return;
        }
        this.showType = type;
        if (type == 1) {
          this.$refs.mind.getParams(conPO, "切换");
        } else {
          // this.$nextTick(() => {
          //   this.$refs.paginationOne.page = 1;
          //   this.$refs.paginationTwo.page = 1;
          //   this.$refs.paginationThree.page = 1;
          // });
          this.getTableData(conPO);
        }
      }
		},
		// // 获取中标单位数据
		// getStaBidWinnerByTendereePage() {
		//   let { winnerParams } = this;
		//   this.$api.successful
		//     .getStaBidWinnerByTendereePage(winnerParams)
		//     .then(res => {
		//       let winnerList = res.rows;
		//       winnerList.forEach((item, index) => {
		//         item.index =
		//           (winnerParams.pageNum - 1) * winnerParams.pageSize + index + 1;
		//       });
		//       this.winnerList = winnerList;
		//       this.winnerTotal = res.total;
		//     })
		//     .catch(msg => {
		//       if (msg?.msg) {
		//         this.$message.error(msg?.msg);
		//       }
		//     });
		// },
		// // 获取地区数据
		// getStaBidWinnerByAreaPage() {
		//   let { areaParams } = this;
		//   this.$api.successful
		//     .getStaBidWinnerByAreaPage(areaParams)
		//     .then(res => {
		//       let areaList = res.rows;
		//       areaList.forEach((item, index) => {
		//         item.index =
		//           (areaParams.pageNum - 1) * areaParams.pageSize + index + 1;
		//       });
		//       this.areaList = areaList;
		//       this.areaTotal = res.total;
		//     })
		//     .catch(msg => {
		//       if (msg?.msg) {
		//         this.$message.error(msg?.msg);
		//       }
		//     });
		// },
		// // 获取项目类型数据
		// getStaBidWinnerByProTypePage() {
		//   let { typeParams } = this;
		//   this.$api.successful
		//     .getStaBidWinnerByProTypePage(typeParams)
		//     .then(res => {
		//       let typeList = res.rows;
		//       typeList.forEach((item, index) => {
		//         item.index =
		//           (typeParams.pageNum - 1) * typeParams.pageSize + index + 1;
		//       });
		//       this.typeList = typeList;
		//       this.typeTotal = res.total;
		//     })
		//     .catch(msg => {
		//       if (msg?.msg) {
		//         this.$message.error(msg?.msg);
		//       }
		//     });
		// },

		// // 中标单位分页方法
		// winnerPaginChange(page, pageSize) {
		//   this.winnerParams.pageNum = page;
		//   this.winnerParams.pageSize = pageSize;
		//   this.getStaBidWinnerByTendereePage();
		// },
		// // 地区分页方法
		// areaPaginChange(page, pageSize) {
		//   this.areaParams.pageNum = page;
		//   this.areaParams.pageSize = pageSize;
		//   this.getStaBidWinnerByAreaPage();
		// },
		// // 项目类型分页方法
		// typePaginChange(page, pageSize) {
		//   this.typeParams.pageNum = page;
		//   this.typeParams.pageSize = pageSize;
		//   this.getStaBidWinnerByProTypePage();
		// },
		// 下载
		toDownload() {
			if (!this.params) {
				// 没点搜索时
				return;
			}
			let {params, showType} = this;
			let paramsObj = JSON.parse(JSON.stringify(params));
			delete paramsObj.pageNum;
			delete paramsObj.pageSize;
			if (showType == 1) {
				// 图谱下载
				this.$api.successful
					.exportStaTenderee()
					.then(() => {
						this.exportImg(document.getElementById("bidwinner"), "统计数据-按中标人", "png");
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				// 表格下载
				const loading = this.$loading({
					lock: true,
					text: "导出中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.successful
					.exportStaBidWinnerByTenderee(paramsObj)
					.then(res => {
            this.$utils.downloadFile(res, "统计数据-按中标人.xlsx");
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
		/*
		 * element  导出的元素
		 * filename 文件名
		 * ext      扩展文件名
		 */
		exportImg(element, filename, ext) {
			const loading = this.$loading({
				lock: true,
				text: "导出中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			html2canvas(element, {
				useCORS: true,
				height: element.scrollHeight,
				windowHeight: element.scrollHeight,
				width: element.width,
				windowWidth: element.width,
			}).then(canvas => {
				loading.close();
				if (navigator.msSaveBlob) {
					const blob = canvas.msToBlob(); // IE10+
					return navigator.msSaveBlob(blob, name);
				} else {
					const imageurl = canvas.toDataURL("image/png");
					const aLink = document.createElement("a"); // 创建a标签
					aLink.style.display = "none";
					aLink.href = imageurl;
					aLink.download = `${filename}.${ext}`; // 下载文件名
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); // 用完后移除元素
				}
			});
		},
		toDetail(row) {
			let param = JSON.parse(JSON.stringify(row));
			param.pageNum = 1;
			param.pageSize = 20;
			let {dateStart, dateEnd, winnerPriceStart, winnerPriceEnd} = this.params;
			param.dateStart = dateStart;
			param.dateEnd = dateEnd;
			param.winnerPriceStart = winnerPriceStart;
			param.winnerPriceEnd = winnerPriceEnd;
			if (param.province) {
				param.area = param.province + (param.city ? ";" + param.city : "") + (param.projectCounty ? ";" + param.projectCounty : "");
			}
			// console.log(param);
			this.$refs.projectModal.init(param);
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
