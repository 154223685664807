<template>
	<div class="customer-main">
		<div class="customer-title-box">
			<div>项目跟踪记录</div>
		</div>
		<!-- <div class="divider"></div> -->
		<div class="search">
			<div class="search-left">
				<span class="search-title">项目名称</span>
				<el-input class="search-input" v-model.trim="keywords" placeholder="请输入项目名称关键字" maxlength="50" clearable></el-input>
				<el-button type="primary" @click="search()" style="margin-left: 20px">搜索</el-button>
			</div>
			<div class="search-right">
				<!-- <div class="right-top">
          <el-button type="primary" @click="search()">搜索</el-button>
        </div> -->
				<div class="right-bottom">
					<div class="btn">
						<span class="btn-style">
							<el-button type="primary" icon="el-icon-plus" @click="handleAdd()">新增项目</el-button>
						</span>
						<el-button type="primary" icon="el-icon-edit" :disabled="single" @click="handleEdit()">编辑</el-button>
						<el-button type="primary" icon="el-icon-delete" :disabled="multiple" @click="handleDel()">删除</el-button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="total == 0" style="margin: 37px 0">
			<empty name="暂无数据"></empty>
		</div>
		<div class="customer-content" v-if="total > 0">
			<div class="customer-box" v-show="total != 0">
				<el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header" @selection-change="handleSelectionChange" v-table-move="['table']">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
					<el-table-column prop="" label="操作" width="80" align="center">
						<template slot-scope="scope">
							<span class="view-style" @click="goDetail(scope.row.projectId)">跟踪详情</span>
						</template>
					</el-table-column>
					<el-table-column prop="projectName" label="项目名称" width="160" :show-overflow-tooltip="true" align="center"></el-table-column>
					<el-table-column prop="projectType" label="项目类型" width="110" align="center"></el-table-column>
					<el-table-column prop="constructionContent" label="建设内容" width="140" :show-overflow-tooltip="true" align="center"></el-table-column>
					<el-table-column prop="decisionMakerInfo" label="决策人信息" width="140" :show-overflow-tooltip="true" align="center">
						<template slot-scope="scope">{{ scope.row.decisionMakerInfo || "--" }}</template>
					</el-table-column>
					<el-table-column prop="contactInfo" label="联络人信息" width="140" :show-overflow-tooltip="true" align="center">
						<template slot-scope="scope">{{ scope.row.contactInfo || "--" }}</template>
					</el-table-column>
					<el-table-column prop="competitor" label="潜在/主要竞争者" width="140" :show-overflow-tooltip="true" align="center">
						<template slot-scope="scope">{{ scope.row.competitor || "--" }}</template>
					</el-table-column>
					<el-table-column prop="unionResources" label="可联合资源" width="140" :show-overflow-tooltip="true" align="center">
						<template slot-scope="scope">{{ scope.row.unionResources || "--" }}</template>
					</el-table-column>
					<el-table-column prop="remarks" label="备注" width="160" :show-overflow-tooltip="true" align="center">
						<template slot-scope="scope">{{ scope.row.remarks || "--" }}</template>
					</el-table-column>
					<el-table-column prop="updateDate" label="最近更新时间" width="160" :show-overflow-tooltip="true" align="center">
						<template slot-scope="scope">{{ scope.row.ts || "-" }}</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination-box">
				<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
			</div>
		</div>
		<!-- 新增项目   dialog -->
		<div class="wai-project">
			<Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
					{{ title }}
				</div>
				<el-form :model="dataPO" :rules="rules" id="form" ref="form" label-width="100px" class="demo-ruleForm">
					<el-row>
						<el-col :span="12">
							<el-form-item label="项目名称" prop="projectName" class="title-item">
								<el-input v-model.trim="dataPO.projectName" placeholder="请输入项目名称" maxlength="50" :show-word-limit="true"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="项目类型" prop="projectType" class="title-item">
								<el-select v-model="dataPO.projectType" placeholder="请选择项目类型" ref="type">
									<el-option v-for="(item, index) in typeList" :key="index" :label="item.categoryName" :value="item.categoryName"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="建设内容" prop="constructionContent" class="title-item">
								<el-input type="textarea" v-model.trim="dataPO.constructionContent" placeholder="请输入建设内容，限200字" :rows="4" maxlength="200" :show-word-limit="true" resize="none"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="投资额" prop="investmentAmount" class="title-item">
								<el-input v-model.trim="dataPO.investmentAmount" @change="handleInputMoney" placeholder="请输入投资额"></el-input>
								<span class="money">万元</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="决策人信息" prop="decisionMakerInfo" class="title-item">
								<el-input v-model.trim="dataPO.decisionMakerInfo" placeholder="请输入决策人信息" maxlength="50" :show-word-limit="true"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="联络人信息" prop="contactInfo" class="title-item">
								<el-input v-model.trim="dataPO.contactInfo" placeholder="请输入联络人信息" maxlength="50" :show-word-limit="true"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="主要/潜在竞争者" prop="competitor" class="title-item">
								<el-input v-model.trim="dataPO.competitor" placeholder="请输入主要/潜在竞争者" maxlength="50" :show-word-limit="true"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="可联合资源" prop="unionResources" class="title-item">
								<el-input v-model.trim="dataPO.unionResources" placeholder="请输入可联合资源" maxlength="100" :show-word-limit="true"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="备注" prop="remarks" class="title-item">
								<el-input type="textarea" v-model.trim="dataPO.remarks" placeholder="请输入备注信息，限200字" :rows="8" :show-word-limit="true" maxlength="200" resize="none"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col>
							<el-form-item class="btn-item">
								<el-button @click="cancel()">取消</el-button>
								<el-button type="primary" @click="submitForm('form')">确认</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</Modal>
		</div>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showMemberBtn="showMemberBtn" :text="text"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import ToPayOrMember from "@/components/ToPayOrMember";

import {price} from "@/utils/validate.js";
export default {
	components: {
		Pagination,
		Empty,
		Modal,
		ToPayOrMember,
	},
	data() {
		return {
			total: 0,
			keywords: "",
			typeList: [],
			tableData: [],
			visible: false,
			title: "新增项目",
			dataPO: {},
			rules: {
				projectName: [{required: true, message: "请输入项目名称", trigger: "blur"}],
				projectType: [{required: true, message: "请选择项目类型", trigger: "change"}],
				constructionContent: [{required: true, message: "请输入建设内容", trigger: "blur"}],
				investmentAmount: [
					{required: true, message: "请输入投资额", trigger: "blur"},
					// {
					//   validator: price,
					//   trigger: "blur"
					// }
				],
			},
			params: {
				pageNum: 1,
				pageSize: 10,
			},
			ids: [],
			// 非单个禁用
			single: true,
			// 非多个禁用
			multiple: true,
			editData: {},
			consumerId: "",
			showMemberBtn: true,
			text: ''
		};
	},
	computed: {},
	created() {
		this.consumerId = this.$session.getUsers().consumerId;
	},
	watch: {
		visible(newName) {
			console.log(newName);
			if (newName) {
				this.$nextTick(() => {
					document.getElementById("form").addEventListener("scroll", this.handleScroll); // 监听页面滚动
				});
			}
		},
	},
	mounted() {
		// this.getClientProject();
	},
	methods: {
		// 监听弹窗页面滚动
		handleScroll() {
			this.$refs.type.visible = false;
		},
		// 获取列表
		async getClientProject() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: true,
			});
			await this.getProjectType();
			await this.$api.personalCenter
				.getClientProject({
					clientId: this.$route.query.id,
					projectName: this.keywords,
					...this.params,
				})
				.then(res => {
					this.tableData = res.data.data;
					this.total = res.data.total;
					this.tableData.forEach(item => {
						this.typeList.forEach(items => {
							if (item.projectType == items.categoryId) {
								item.projectTypeName = items.categoryName;
							}
						});
					});
					loading.close();
				})
				.catch(msg => {
					loading.close();
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取类型列表
		getProjectType() {
			this.$api.personalCenter
				.getProjectType()
				.then(res => {
					this.typeList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},

		// 搜索
		search() {
			this.params.pageNum = 1;
			this.params.pageSize = 10;
			this.getClientProject();
		},
		// 复选框选择事件
		handleSelectionChange(selection) {
			console.log(selection);
			this.ids = selection.map(item => item.projectId);
			this.single = selection.length != 1;
			this.multiple = !selection.length;
			if (!this.single) {
				this.editData = selection[0];
			}
			// selection.forEach(items => {
			//   if (items.consumerId != this.consumerId) {
			//     this.single = true;
			//     this.multiple = true;
			//     this.$message.error("被共享的项目暂不支持操作！");
			//     return false;
			//   }
			// });
			let arr = selection.map(item => item.consumerId == this.consumerId);
			if (arr.indexOf(false) != -1) {
				this.single = true;
				this.multiple = true;
				this.$message.error("被共享的项目暂不支持编辑与删除！");
				return false;
			}
		},
		// 新建成员按钮
		handleAdd() {
			this.visible = true;
			this.title = "新增项目";
			this.dataPO = {};
			// this.resetFields();
		},
		// 保留两位小数方法
		calcNum(val) {
			return Number(val).toFixed(2);
		},
		//  判断输入价格
		handleInputMoney(val) {
			val = val.replace(/[^0-9.]/g, "");
			if (+val >= 0) {
				const reg = /^[0-9]{1,10}([.][0-9]{1,4})?$/;
				if (reg.test(val)) {
					return;
				} else {
					this.dataPO.investmentAmount = null;
					this.$message.error("最多10位整数，4位小数");
					return false;
				}
			} else {
				this.$message.error("投资额不应小于0！");
				this.dataPO.investmentAmount = null;
				return false;
			}
		},
		// 修改按钮
		handleEdit() {
			this.visible = true;
			this.title = "修改项目";
			this.dataPO = JSON.parse(JSON.stringify(this.editData));
			// this.typeList.forEach(item => {
			// 	if (this.dataPO.projectTypeName == item.categoryName) {
			// 		this.dataPO.projectType = item.categoryId;
			// 	}
			// });

			// this.resetFields();
		},
		// 点击进入跟踪详情
		goDetail(val) {
			console.log(val);
			this.$router.push({
				path: "/industry/trackRecord-detail",
				query: {
					id: val,
					clientId: this.$route.query.id,
					pathType: this.$route.query.pathType,
					type: this.$route.query.type,
				},
			});
		},
		// 删除方法
		handleDel(val) {
			this.$confirm("删除后将不能撤回，是否删除?", "提示", {
				confirmButtonText: "确认删除",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$api.personalCenter
						.deleteClientProject(this.ids.join(","))
						.then(res => {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.getClientProject();
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				})
				.catch(() => {});
		},
		// 切换分页
		paginChange(page, pageSize) {
			this.params.pageNum = page;
			this.params.pageSize = pageSize;
			this.getClientProject();
		},
		// 关闭弹窗时重置表单
		resetFields() {
			this.$refs.form.resetFields();
			this.dataPO = {};
		},
		// 表单提交事件
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.dataPO.projectId) {
						this.$api.personalCenter
							.updateClientProject({
								...this.dataPO,
								clientId: this.$route.query.id,
							})
							.then(res => {
								this.visible = false;
								this.$message.success("修改成功");
								this.resetFields();
								this.getClientProject();
							})
							.catch(msg => {
								if (msg?.msg) {
									this.$message.error(msg?.msg);
								}
							});
					} else {
						this.$api.personalCenter
							.addClientProject({
								...this.dataPO,
								clientId: this.$route.query.id,
							})
							.then(res => {
								this.visible = false;
								this.$message.success("新增成功");
								this.resetFields();
								this.getClientProject();
							})
							.catch(msg => {
								if (msg?.msg) {
									this.$message.error(msg?.msg);
								}
							});
					}
				} else {
					document.getElementById("form").scrollTop = 0;
					return false;
				}
			});
		},
		// 表单取消
		cancel() {
			this.visible = false;
			this.$refs.form.clearValidate();
			this.resetFields();
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
